/* Plugins */
import _ from 'lodash';
import moment from 'moment'

/* Helper */
import Highlight from '@/helpers/highlightWord';

export const utilsMixins = {
    data() {
        return {
            copiedContent      : null,
            utilsDateFormatting: '#########',
            processingHighlight : false
        }
    },
    computed : {
        /* Logged in user */
        user() {
            return this.$store.state.user;
        }
    },
    methods : {
        /**
         * Quote sorting
         * @param {String} sorting asc / desc / alpha
         * @param {Array} data quote and story list 
         */
        quoteSorting({sorting = 'desc', data = []} = {}) {
            let list = [...data]
             /* Sort by latest */

            //  console.log('sorted quote', sorting)

             if(sorting == 'desc') {
                // list.sort((a, b) => new Date(b.source_date_created) - new Date(a.source_date_created))
                    


                list.sort((a, b) => {
                    /* Sort by date */
                    /* Return same position if no date */
                    if(!b.source_date_created) return 0;
                    if(!a.source_date_created) return 0;

                    /* Sort latest date first */
                    if(moment(b.source_date_created).isAfter(moment(a.source_date_created))) return 1;
                    if(moment(b.source_date_created).isBefore(moment(a.source_date_created))) return -1;
                    if(moment(b.source_date_created).isSame(moment(a.source_date_created))) {
                    // console.log('sorted desc', b.s_page_start, a.s_page_start, data)


                        /* If same date, and pages then sort by id */
                        if(b.s_page_start == a.s_page_start && b.s_page_end == a.s_page_end) {
                            if(b.id > a.id) return -1;
                            if(b.id < a.id) return 1;

                            return 0;
                        }

                        // console.log('sorted by date and page', b.s_page_start, a.s_page_start)


                        /**
                         * Has different page but same date
                         */
                        /* If same date and then sort by page number */
                        if(b.s_page_start > a.s_page_start) return -1;
                        if(a.s_page_start > b.s_page_start) return 1;
                        if(b.s_page_start == a.s_page_start) {
                            if(!b.s_page_end) return 0;
                            if(!a.s_page_end) return 0;

                            if(b.s_page_end > a.s_page_end) return 1;

                            return 1;
                        } 
                     
                    }

                    return 0;

                })

                return list;
            }
            /* Sorting by ascending quote / story date */
            if(sorting == 'asc') {

                /* Sorted descending by quote date / story date */
                list.sort((a, b) => {
                    /* Sort by date */
                    /* Return same position if no date */
                    if(!b.source_date_created) return 0;
                    if(!a.source_date_created) return 0;

                    /* Sort latest date first */
                    if(moment(b.source_date_created).isAfter(moment(a.source_date_created))) return -1;
                    if(moment(b.source_date_created).isBefore(moment(a.source_date_created))) return 1;
                    if(moment(b.source_date_created).isSame(moment(a.source_date_created))) {
                        /* If same date, and pages then sort by id */
                        if(b.s_page_start == a.s_page_start && b.s_page_end == a.s_page_end) {
                            if(b.id > a.id) return 1;
                            if(b.id < a.id) return -1;

                            return 0;
                        }

                         /* If same date sort by page number */
                        if(b.s_page_start > a.s_page_start) return 1;
                        if(b.s_page_start == a.s_page_start) {
                            if(!b.s_page_end) return 0;
                            if(!a.s_page_end) return 0;
                            if(b.s_page_end > a.s_page_end) return -1;

                            return -1;
                        } 
                    }

                    return 0;

                })

                return list;
            }

            /* Quote sorting by source name */
            if(sorting == 'alpha') {
                list = list.map(pc => {
                    pc.content = pc.story ? pc.story : pc.quote ? pc.quote : ''
                    return pc
                })

                list.sort((a, b) => {
                    if(a?.source?.source_name < b?.source?.source_name) { return -1; }
                    if(a?.source?.source_name > b?.source?.source_name) { return 1; }
                    return 0;
                });

                return list;
            }

        },
        /**
         * Highlight search result
         * @param {String} searchString
         */
        startHighlight : _.debounce(function (searchString) {
            /* search hightlight*/
            const els = document.querySelectorAll('.quoteitem p');
            const elsContainers = document.querySelectorAll('.quoteitem .qoutebody');

            if(searchString) {
                Highlight.MarkHighlight({elements : els, string : searchString, containers : elsContainers, hasPartial : true, callback : () => {
                    setTimeout(() => {
                        this.processingHighlight = false
                    }, 150);
                }});
            } else {
                /* Remove highlight */
                Highlight.RemoveHighlight({elements : elsContainers, callback : () => {
                    // console.log('remove highlights ', els)

                    setTimeout(() => {
                        this.processingHighlight = false
                    }, 150);
                }});
            }
    
        }, 300),
        /* Complete the date for monthyear or year only */
        completeTheDate(date) {
            const dateType = this.getDateType(date);


            if(dateType == 'year') {
                return `1/1/${date}`
            } 
          
            if(dateType == 'monthyear') {
                const newDate =  date.split('/');
                return `${newDate[0]}/01/${newDate[1]}`;
            } 
            // console.log('complete date', dateType)

            return date;
        },
        /* Check if valid date */
        isValidDate(date) {
            return moment(date).isValid();
        },
        /* check if within user level */
        canAccess(userLevel) {
            return userLevel <= this.user?.user_level ? true : false;
        },
        /* Check if your the owner */
        iOwned(createdBy) {
            // console.log('init createdby', createdBy);

            if(!createdBy) return false;
            
            const createdID = createdBy?.id ?? createdBy;

            // console.log('createdIDcreatedID', createdID)

            return this.user?.id == createdID ? true : false;
        },
        /* Dynamic detect date format */
        dateFormatMasking : _.throttle(function(event, callback = () => {}) {
            const date = event?.target?.value || event;
            console.log(date, 'what is event date format masking', event)

            if(!date) return;


            let maskFormat = '';

            if(date.length > 4 && date.length < 8) {
                maskFormat = '##/######';
            } else if(date.length >= 8) {
                maskFormat = '##/##/####';
            } else {
                maskFormat = '########';
            }
            
            this.utilsDateFormatting = maskFormat;

            // console.log(maskFormat, 'what is this date', date, date.length);


            callback(maskFormat);
        }, 50),
        /* Reset scroll */
        resetScroll(el) {
            if(!el) return;
            el.scrollTop = 0;
        },
        /* Check if date type */
        getDateType(date) {

            if(!date) return null;

            if(date.length == 4) {
                /* #### */
                return 'year'
            }

            if(date.length == 7 || date.length == 6) {
                /* ##/#### */
                return 'monthyear'   
            }

            if(date.length == 10 || date.length == 8) {
                /* ##/##/#### */
                return 'full'
            }

            return null;

        },
         /* Check if has note or none */
         hasNote(item) {
            // /* Story */
            // if(item.story) {
            //     return item.story_notes ? true : false
            // }

            // /* Quote  */
            // if(item.quote) {
            //     return item.quote_notes ? true : false
            // }

            return item?.s_notes ?? false;

        },
        /* Hide show note */
        toggleNote(item) {
            let type                    = ''
            if (item.story) type        = 'story';
            if (item.quote) type        = 'quote';
            if (item.poem_content) type = 'poem';
            if (item.song_content) type = 'song';
            
            
            const showNote = item.id+type;
            // console.log('toggle note', showNote)
            this.showNote = showNote == this.showNote ? null : showNote
        },
        /* generate number progress */
        generateFakeProg({callback = () => {}} = {}) {
            let currProg = 0;
            const progressCount = setInterval(() => {
                const min = 0;
                const max = 10;
                
                if(currProg > 90) {
                    clearInterval(progressCount);
                }

                callback(currProg);
                currProg = currProg + (Math.random() * (max - min) + min);
            }, 1000);


        },
        /* Toggle see more */
        toggleSeemore(selector) {
            const el = document.getElementById(selector);
            // const [el] = this.$refs[selector]
            // console.log('el toggleClass', el)
            if(!el) return;

            el.classList.toggle('seeMore')
        },
        /* checkif textoverflow */
        textOverFlow : _.debounce(function() {
            // console.log('selector', selector);

            // const  isEllipsisActive = (e) => {
            //     return (e.scrollHeight > e.clientHeight);
            // }

            // const items = document.querySelectorAll(selector);

            // console.log('text ellipsis', items)


            // items.forEach(i => {
                // console.log('isEllipsis', isEllipsisActive(i))
            //     if(isEllipsisActive(i)) {
            //         if(!Array.from(i.classList).includes('isEllipsis')) {
            //             i.classList.add('isEllipsis')
            //         }
            //     }
            // })
        }, 100),
        /* Copy string to clipboard */
        copyClipboard({string, callback = () => {}} = {}) {
            // Copy the text inside the text field
            navigator.clipboard.writeText(string);

            this.copiedContent = string;

            setTimeout(() => {
                callback();
                this.copiedContent = null
            }, 2000);
        },
        /* is image */
        isImage(url) {
            if(!url) return; // prevent if no url
            return url.match(/.jpg|.png|.JPG|.PNG|.jpeg/i);
        },
        /* is audio */
        isAudio(url) {
            if(!url) return; // prevent if no url
            return url.match(/.mp3|.ogg|.wav/i)
        },
        /* Check if video */
        isVideo(url) {
            if(url && url.toLowerCase().match(/.mp4|.mov|.avi|.mkv|.mov/)) {
                return true
            } 

            return false
        },
    }
}