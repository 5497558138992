<template>
	<div class="header">
		<div class="container">
			<div class="grid grid-cols-12 gap-4">
				<div class="col-span-8">
					<div class="headeraction left">
						<a href="" @click.prevent="() => emit('back')" class="headeractionitem backbtn flex items-center">
							<quote-icon class="!w-5 mr-2" icon="chevron-left"></quote-icon> {{label}}
						</a>
					</div>
				</div>

				<div class="col-span-4">
					<div v-if="isEdit && !isLoading" class="headeraction right">
						<a 
							v-if="hasSearchBtn"
							:class="['headeractionitem hover:!bg-orange !bg-white', {'active' : showSearch}]" @click.prevent="emit('toggleSearch')" href="">
							<quote-icon class="!w-4" icon="search" />
						</a>

						<a 
							v-if="hasEditBtn"
							@click.prevent="() => emit('edit')"
							href="" 
							class="headeractionitem hover:!bg-orange !bg-white">
							<quote-icon class="!w-4" icon="pencil"/>
						</a>

						<a @click.prevent="emit('delete')" href="" class="headeractionitem">
							<quote-icon icon="trash-outline"></quote-icon>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
/* ========= UTILS ===== */
import { defineProps, defineEmits } from 'vue'

/* ========= PROPS ====== */
defineProps({
	isLoading: {type : Boolean, default : () => false},
	label: {type : String, default : () => ''},
	isEdit: {type : Boolean, default : () => false},
	hasEditBtn: {type : Boolean, default : () => false},
	showSearch: {type : Boolean, default : () => false},
	hasSearchBtn: {type : Boolean, default : () => false}
})

/* ============= EMITS ========= */
const emit = defineEmits([
	'back',
	'delete',
	'toggleSearch'
])

/* ============= MODELS ======= */
</script>
