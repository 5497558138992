/**
 * Tag Resource
 * @namespace TagResource
 */

/* Utils */
import {getCollection, getCollections} from '@/manie-utils/idb/localbase';


export default class TagResource {
    /**
     * Return tag Object
     * @param {Number} id
     * @param {Number} user_id
     */
    static async getTag({tag_id, user_id}) {

        if(!tag_id) return null;

        const tag     = await getCollection({name : 'tags', filter : {id : tag_id}});
        if(!tag) return null;

    
        /* check if has published snippets */
        const snippetTags = await getCollections({name : 'snippet_tags', filter : {key : 'tag_id', value : tag_id}})
        let   snippets    = []



        for (let index = 0; index < snippetTags.length; index++) {
            const snippet = snippetTags[index];
            snippets = [...snippets, await getCollection({name : 'snippets', filter : {id : snippet.snippet_id}})];
        }

        /* Remove non existing snippets */
        snippets = [...snippets.filter(s => s)];


        const admin_lock = snippets.find(s => s.admin_lock) ? true : false;

        /* Add tag rating popularity */
        const rating = (tag.tag_counter + snippetTags.length)

        /* used by the user */
        let is_used = false;
        if(user_id) is_used = snippets.find(s => s.created_by?.id == user_id || s?.created_by == user_id) ? true : false;

     
        /* Merged properties */
        const additionalProperties  =  {
            admin_lock,
            rating,
            is_used
        }


        return {...tag, ...additionalProperties}
    }
}