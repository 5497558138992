<template>
	<div :class="['attachementWrap', { 'isLoading': isLoading }]">
		<h1 class="mb-1">Attachment
			<a class="!text-black dark:!text-white" v-if="isEdit" @click.prevent="() => editAttachment()"
				href=""><quote-icon class="!w-4 !h-4" icon="pencil"></quote-icon></a>
		</h1>

		<div class="attachmentBody">

			<div v-if="isUploading" class="progressWrapper">
				<b-progress :value="uploadProgress" :max="100" animated>
				</b-progress>
			</div>


			<template v-if="attachement">
				<template v-if="isImage(newFile ? newFile.name : attachement)">
					<img :src="attachement" alt="">
				</template>

				<audio v-else-if="isAudio(newFile ? newFile.name : attachement)" controls :src="attachement"></audio>

				<video v-else controls :src="attachement"></video>
			</template>

			<label v-if="!isEdit && !attachement" for="">No attachment</label>

			<label v-show="!attachement && isEdit">
				Add Audio / Video / Photo

				<input ref="attachmentRef" @change="getFile" accept="video/*, audio/*, image/*" type="file" id="attachment"
					style="display:none;">
			</label>
		</div>



	</div>
</template>

<script setup >
/* ========= UTILS ===== */
import { defineProps, defineModel, ref } from 'vue'


/* ========= PROPS ====== */
defineProps({
	isLoading: {type : Boolean, default : () => false},
	isUploading: {type : Boolean, default : () => false},
	uploadProgress: {type : Number, default : () => 0},
	attachement: {type : String, default : () => ''},	
	isImage: {type : Function, default : () => {}},
	newFile: {type : Object, default : () => {}},
	isAudio: {type : Function, default : () => {}},
	getFile: {type : Function, default : () => {}},
	isEdit : {type : Boolean, default : () => false}
})

/* ========= STATES  ====== */
const attachmentRef  = ref(null)

/* ========= MODLES  ====== */
const showUploadDZ = defineModel('showUploadDZ', {
	type: Boolean,
	default: false
})

/* ========= METHODS ======= */
 /**
 * Edit attachment
 */
const editAttachment = () => {
	if(window?.innerWidth > 1010) {
		if(window.navigator.standalone === true) {
			showUploadDZ.value = true
		} else attachmentRef.value.click()
	} else attachmentRef.value.click()
}
</script>
