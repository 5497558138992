/**
 * Tag models
 * Object property that allow to be save in indexdb
 * @namespace TagModel
 */

export default class TagModel {
    /**
     * Destructuring object and only return decleared properties
     */
    static schema (object) {
        const  {
            id          = null,
            tag_counter = null,
            tag_name    = null,
            created_by  = null,
            }           = object;



        return {
            id,
            tag_counter,
            tag_name,
            created_by : created_by?.id || created_by
        }

    }

}