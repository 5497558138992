<template>
	<div :key="reRenderEditor + 'quotemeta'" class="quotemeta">
		<h1>Metadata</h1>

		<table class="table">
			<tbody>
				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Author</td>
					<td :class="[{ 'isLoading': isLoading }]">
						<div class="input-group">
							<input :readonly="!isEdit" @input="() => (emit('findAuthor'), searchingAuthorType = 'author')"
								v-model="authorName" placeholder="-" type="text" class="form-control w-full">


							<span class="block w-8 h-8"
								v-if="isSearchingAuth && authorName && searchingAuthorType == 'author'">
								<a @click.prevent="$router.push({ name: 'newAuthor', query: { authorName: authorName } })"
									href=""><quote-icon class="text-orange ml-2"
										icon="plus-circle-fill"></quote-icon></a>
							</span>

						</div>

						<div v-if="authorList.length > 0 && searchingAuthorType == 'author'" class="popList">

							<ul class="list-group">
								<li class="list-group-item" @click="() => emit('setAsNewAuthor', author)" v-for="author in authorList"
									:key="author.id">

									{{ author.first_name }} {{ author.last_name }}
								</li>
							</ul>

						</div>
					</td>
				</tr>

				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Position</td>
					<td :class="[{ 'isLoading': isLoading }]">
						<div >
							{{ authorPosition || '-' }}
						</div>
					</td>
				</tr>

				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Author Type</td>
					<td :class="[{ 'isLoading': isLoading }]">
						<template v-if="!isEdit">
							{{ selectedAuthorType || '-' }}
						</template>
						<m-dropdown v-else buttonClass="!normal-case" @selected="(value) => quote.author_type = value"
							:buttonLabel="selectedAuthorType" :options="authorTypeDPOPT" />
					</td>
				</tr>

				<tr v-if="quote.author_type && quote.author_type != '-'">
					<td :class="[{ 'isLoading': isLoading }]">Author by</td>
					<td :class="[{ 'isLoading': isLoading }]" class="relative">
						<m-input-search-pop 
							:isEdit="isEdit" 
							:resultList="modifiedAuthorList" 
							:showResult="authorList.length > 0 && searchingAuthorType == 'author_by'"
							@searching="() => (emit('findAuthor'), searchingAuthorType = 'author_by')"
							@selected="author => emit('setAuthorBy', author)"
							@close="() => emit('close')"
							v-model:searchString="referenceAuthor" />

					</td>
				</tr>

				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Compilation</td>
					<td :class="[{ 'isLoading': isLoading }]">
						<div class="input-group">
							<input :readonly="!isEdit" @input="() => emit('filterCompi')" v-model="compilationName" placeholder="-"
								type="text" class="form-control w-full">


							<span class="block w-8 h-8"
								v-if="compilationName && searchingCompi && !existingCompilation">

								<a @click.prevent="$router.push({ name: 'newCompilation', query: { compiName: compilationName } })"
									href=""><quote-icon class="text-orange ml-2"
										icon="plus-circle-fill"></quote-icon></a>

							</span>
						</div>


						<div v-if="compilationList.data.length > 0" class="popList">
							<ul class="list-group">
								<li v-for="compi in compilationList.data" @click="() => emit('setNewCompilation',compi)"
									:key="compi.id" class="list-group-item">
									{{ compi.sc_name }}
								</li>
							</ul>

						</div>
					</td>
				</tr>

				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Source</td>
					<td :class="[{ 'isLoading': isLoading }]">
						<div class="input-group">
							<input :readonly="!isEdit" @input="() => emit('filterSources')" v-model="quote.source.source_name"
								placeholder="-" type="text" class="form-control w-full">

							<span class="block w-8 h-8"
								v-if="!existingSource && isSearchingSource && quote.source.source_name">
								<a @click.prevent="$router.push({ name: 'newSource', query: { sourceName: quote.source.source_name, compilation: JSON.stringify(compilationObjct) } })"
									href=""><quote-icon class="text-orange ml-2"
										icon="plus-circle-fill"></quote-icon></a>
							</span>
						</div>


						<div v-if="sourceList?.length > 0" class="popList">

							<ul class="list-group">
								<li @click="() => emit('setAsNewSource', source)" v-for="source in sourceList" :key="source.id"
									class="list-group-item">
									{{ source.source_name }}
								</li>
							</ul>
						</div>
					</td>
				</tr>

				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Subtitle</td>
					<td :class="[{ 'isLoading': isLoading }]">
						

						<div v-if="!isLoading" v-content-editor="{
							initialContent: source_subtitle,
							readonly: !isEdit,
							callback: (string) => source_subtitle = string,
							placeholder: '-'
						}" class="form-control break-all">
						</div>
					</td>
				</tr>

				<tr>
					<td :class="['nowrap', { 'isLoading': isLoading }]">Subtitle Note</td>
					<td :class="[{ 'isLoading': isLoading }]">
						<div v-if="!isLoading" v-content-editor="{
							initialContent: subtitleNote,
							readonly: !isEdit,
							callback: (data) => emit('updateSutitleNote', data),
							placeholder: '-'
						}" class="form-control break-all">
						</div>
					</td>
				</tr>

				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Page(s)</td>
					<td :class="[{ 'isLoading': isLoading }]">

						<input :readonly="!isEdit" v-model="pageNumber" placeholder="-" type="text"
							class="form-control w-full">


					</td>
				</tr>

				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Date</td>
					<td :class="[{ 'isLoading': isLoading }]">
						<input :readonly="!isEdit" v-mask="'date'"
							@input="(event) => dateFormatMasking(event, saveSourceDate)" @blur="() => emit('saveSourceDate')"
							v-model="source_date" placeholder="-" type="text" class="form-control w-full">
					</td>
				</tr>

				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Visibility</td>
					<td :class="[{ 'isLoading': isLoading }]">

						<template v-if="isForApproval">

							<button
								:class="[{ 'btn-primary': approvalState }, { 'btn-primary-outlined': !approvalState }]"
								class="approvalBtn btn"
								@click="approvalState = approvalState ? null : true">Approve</button>

							<button @click="approvalState = approvalState == false ? null : false"
								class="approvalBtn rejectBtn btn"
								:class="[{ 'btn-primary': !approvalState }, { 'btn-primary-outlined': approvalState }]">Reject</button>

						</template>

						<template v-else-if="user?.is_admin && quote.publish_status">
							<button class="btn btn-primary" @click="() => emit('adminUnpublished')">Unpublish</button>
						</template>

						<template v-else-if="!isEdit">
							<input :readonly="!isEdit" :value="quote.publish_status ? 'Public' : 'Private'" type="text"
								class="form-control w-full">
						</template>

						<template v-else>
							<button class="btn btn-primary" :disabled="quote.publish_status"
								@click="() => emit('setVisibility', true)"
								:class="[{ 'default-btn': !quote.visibility }]">Public</button>

							<button v-if="!quote.publish_status" :disabled="quote.publish_status"
								@click="() => emit('setVisibility', false)" class="btn btn-primary"
								:class="[{ 'default-btn': quote.visibility }]">Private</button>

						</template>
					</td>
				</tr>
				<tr v-if="user && user.is_admin && isEdit">
					<td :class="[{ 'isLoading': isLoading }]">User Level</td>
					<td :class="[{ 'isLoading': isLoading }]">

						<dropdown class="!z-30" :buttonLabel="quote.user_level"
							@selected="(value) => emit('changeQSUserLevel', value)" :options="userLevelOptions" />


					</td>
				</tr>


				<tr>
					<td :class="[{ 'isLoading': isLoading }]">Url</td>
					<td :class="[{ 'isLoading': isLoading }]">
						<div v-if="!isLoading" v-content-editor="{
							initialContent: urlLink,
							readonly: !isEdit,
							callback: (string) => emit('updateUrl', string),
							placeholder: '-'
						}" class="form-control break-all pt-1 min-h-[3.36rem]">
						</div>
					</td>
				</tr>

				<tr v-if="isEdit">
					<td :class="[{ 'isLoading': isLoading }]">Type</td>
					<td :class="[{ 'isLoading': isLoading }, '!pt-2']">
						<div class="min-h-[3.36rem]">
							<dropdown class="!z-10" :buttonLabel="quote.s_category"
								@selected="(category) => emit('setSelectedCategory', category)" :options="typeOptions" />
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup >
/* =========== UTILS ====== */
import { defineProps, defineEmits, defineModel, computed } from 'vue'

/* =========== PROPS ====== */
const  props = defineProps(
	{
	isLoading          : {type: Boolean, default: () => false},
	authorList         : {type : Array, default : () => []},
	authorPosition     : {type : String, default : () => ''},
	authorTypeDPOPT    : {type : Array, default : () => []},
	existingCompilation: {type : Boolean, default : () => false},
	compilationList    : {type : Object, default : () => {}},
	existingSource     : {type : Boolean, default : () => false},
	compilationObjct   : {type : Object, default : () => {}},
	sourceList         : {type : Array, default : () => []},
	subtitleNote       : {type : String, default : () => ''},
	dateFormatMasking  : {type : Function, default : () => {}},
	isForApproval      : {type : Boolean, default : () => false},
	userLevelOptions   : {type : Array, default : () => []},
	urlLink            : {type : String, default : () => ''},
	typeOptions        : {type : Array, default : () => []},
	reRenderEditor     : {type : Number, default : () => 0},
	isEdit             : {type : Boolean, default : () => false},
	isSearchingAuth    : {type : Boolean, default : () => false},
	searchingCompi     : {type : Boolean, default : () => false},
	isSearchingSource  : {type : Boolean, default : () => false},
	user			   : {type : Object, default : () => {}}
}
)

/* =========== EMITS ====== */
const emit = defineEmits([
	'setAsNewAuthor',
	'findAuthor',
	'setAuthorBy',
	'filterCompi',
	'setNewCompilation',
	'filterSources',
	'setAsNewSource',
	'updateSutitleNote',
	'saveSourceDate',
	'adminUnpublished',
	'setVisibility',
	'changeQSUserLevel',
	'updateUrl',
	'setSelectedCategory',
	'close'
])


/* =========== MODELS ====== */
const searchingAuthorType = defineModel('searchingAuthorType', { type : String, default : () => '' })
const authorName          = defineModel('authorName', { type : String, default : () => '' })
const compilationName     = defineModel('compilationName', { type : String, default : () => '' })
const source_subtitle     = defineModel('source_subtitle', { type : String, default : () => '' })
const approvalState       = defineModel('approvalState', { type : Boolean, default : () => false })
const pageNumber          = defineModel('pageNumber', { type : String, default : () => '' })
const source_date         = defineModel('source_date', { type : String, default : () => '' })
const quote               = defineModel('quote', { type : Object, default : () => {} })
const referenceAuthor     = defineModel('referenceAuthor', { type : String, default : () => '' })


/* ========== COMPUTED ========== */

/**
 * @description: This computed property is used to get the selected author type
 */
const selectedAuthorType = computed(() => {
	return quote.value?.author_type == '-' ? '' : quote.value?.author_type || ''
})

/**
 * @description: This computed property is used to get the modified author list with label
 */
const modifiedAuthorList = computed(() => {
	return [...props.authorList || []].map((author) => {
		return {
			...author,
			label: `${author.first_name} ${author.last_name}`
		}
	})
})
</script>
