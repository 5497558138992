/**
 * Author models
 * Object property that allow to be save in indexdb
 * @namespace AuthorModel
 */

export default class AuthorModel {
    /**
     * Destructuring object and only return decleared properties
     */
    static schema (object) {
            const  {
            id                     = null,
            first_name             = null,
            last_name              = null,
            author_img             = null,
            notes                  = null,
            author_birth_date      = null,
            author_birth_date_type = null,
            author_death_date      = null,
            author_death_date_type = null,
            created_by             = null,
            author_birth_date_circa = null,
            author_death_date_circa = null,
            is_nonauthor = false,
            }                      = object;



        return {
            id,
            first_name,
            last_name,
            author_img,
            notes,
            author_birth_date,
            author_birth_date_type,
            author_death_date,
            author_death_date_type,
            author_birth_date_circa : author_birth_date_circa ? 1 : 0 ,
            author_death_date_circa : author_death_date_circa ? 1 : 0 ,
            created_by : created_by?.id || created_by,
            is_nonauthor
        }

    }

}