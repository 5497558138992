/**
 * File utils
 * @namespace FileUtils
 */

export default class FileUtils {
    /**
     * AWS URL Full path
     */
    static fullPath({url, isDirect = false} = {}) {
        const directAwsBase = process.env.VUE_APP_AWSURLDIRECT
        const baseUrl = isDirect ? directAwsBase : process.env.VUE_APP_AWS_URL
        return url && !url.match('http') ? `${baseUrl}/${url}` : url
    }
}