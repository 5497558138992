/**
 * Highlight word 
 * @namespace Highlight
 * 
 */

export default class Highlight {
    /**
     * Mark the word as Highlighted 
     * @memberof Highlight
     * @function MarkHighlight
     * @param {Array} elements // elements where you want to look
     * @param {String} string // word you want to highlight
     * @param {Array} containers elements container of the content only for hasPartial is true
     * @param {Boolean} hasPartial show partials string of the result as initial display
     * @param {Function} callback
     */

    static MarkHighlight ({elements = [], string = "", perWord = false, containers = [], hasPartial = false, callback = () => {}} = {}) {
            console.log(hasPartial, 'searching', elements, string, containers)

            let   searchWords = string;
            searchWords = searchWords.replace('(', '\\(');
            searchWords = searchWords.replace(')', '\\)');
        const regex = new RegExp(`(${searchWords})`, 'gi');

            /* Set highlight   */
            /**
             * @param {Object} el
             * @param {String} text
             * @param {Array} searchWords
             */
             const setHightlight = ({el, text, searchWords}) => {
                  /* Loop and find search words */

                  searchWords.forEach(word => {
                    const regex = new RegExp(`(${word})`, "gi");

                    // Check if the element's text contains the search term
                    if (text.match(regex)) {
                        // Split the text into parts (matched and unmatched)
                        const parts = text.split(regex);
                        // Create a new HTML structure with the matched term highlighted
                        text = parts
                            .map((part) =>
                            part.match(regex)
                                ? `<mark>${part}</mark>`
                                : part
                            )
                            .join("");
                        }
                })

                el.innerHTML = text
             }

            /* Highlight Meta data */
            const highlightMetaData = () => {

                /* Split search string into words */
                let perWordSearch = searchWords.split(/(\s+)/);
            
                /* Remove empty string and trim the words */
                perWordSearch = perWordSearch.map(sw => sw.trim()).filter(sw => sw);

                /* Author highlight */
                const AuthorCont = document.querySelectorAll('.authorname') || []
                AuthorCont.forEach(el => {
                    let text =  el.innerText
                    /* Loop and find search words */
                    setHightlight({el, text, searchWords : perWordSearch})
                })

                /* Source, compilation, sub title, date, page */
                const sourceSection = document.querySelectorAll('.quotemeta a')

                sourceSection.forEach(el => {
                    let text =  el.innerText
                    /* Loop and find search words */
                    setHightlight({el, text, searchWords : perWordSearch})
                })

                /* Notes */
                let notes = document.querySelectorAll('.sourceNote')
                notes = [...notes, ...document.querySelectorAll('.notesWrap') || []]

                notes.forEach(el => {
                    let text =  el.innerText
                    /* Loop and find search words */
                    setHightlight({el, text, searchWords : perWordSearch})
                })

                /* Tags */
                const tags = document.querySelectorAll('.quotetags .tag')

                tags.forEach(el => {
                    let text =  el.innerText
                    /* Loop and find search words */
                    setHightlight({el, text, searchWords : perWordSearch})
                })


            }

            /* Normal highlight will look for the word highlight them and display the whole content */
            const normalHighlight = () => {
                    // Loop through each element
                    elements.forEach((element) => {
                        const text = element.innerText;
                        let highlightedText = text;

                        if(!perWord) {
                                /* Split search string into words */
                                
                                // Check if the element's text contains the search term
                                if (text.match(regex)) {
                                    // Split the text into parts (matched and unmatched)
                                    const parts = highlightedText.split(regex);
                                    // Create a new HTML structure with the matched term highlighted
                                    highlightedText = parts
                                        .map((part) =>
                                            part.match(regex)
                                            ? `<mark>${part}</mark>`
                                            : part
                                        )
                                        .join("");
                                    }
                            
                        } else {

                            /* Split search string into words */
                            let searchWords = string.split(/(\s+)/);
            
                            /* Remove empty string and trim the words */
                            searchWords = searchWords.map(sw => sw.trim()).filter(sw => sw);
            
                            /* Loop and find search words */
            
                            searchWords.forEach(word => {
                                const regex = new RegExp(`(${word})`, "gi");
            
                                // Check if the element's text contains the search term
                                if (text.match(regex)) {
                                    // Split the text into parts (matched and unmatched)
                                    const parts = highlightedText.split(regex);
                                    // Create a new HTML structure with the matched term highlighted
                                    highlightedText = parts
                                        .map((part) =>
                                        part.match(regex)
                                            ? `<mark>${part}</mark>`
                                            : part
                                        )
                                        .join("");
                                    }
                            })
                        }
                        

                        // Replace the original text with the highlighted version
                        element.innerHTML = highlightedText;
                    });

                    /* Highlight Metadata */
                    highlightMetaData()
            }
            

            /* Partial display result */
            if(hasPartial) {
                /* Loop containers and get all content */
                containers.forEach(cont => {
                  

                    const text = cont.innerText;
                    let highlightedText = text;

                    console.log('searching text', text)
                        
                    /* Split search string into words */
                    // let   searchWords = string;
                    // const regex       = new RegExp(`(${searchWords})`, "gi");
                    // Check if the element's text contains the search term
                    if (text.match(regex)) {
                        // Split the text into parts (matched and unmatched)
                        const parts = highlightedText.split(regex);
                        // Create a new HTML structure with the matched term highlighted

                        /* Find in the parts where this regex is  */
                        const partIndex      = parts.findIndex(p => p.match(regex));
                        const part           = parts.find(p => p.match(regex));
                        const beforeString   = partIndex > 0 ? parts.slice(0, partIndex).join('') : '';
                        // const beforeString   = partIndex > 0 ? parts[partIndex - 1] : '';
                        const afterString    = partIndex < parts.length ? parts[partIndex + 1] : '';
                        const partialsToShow = 100;

                        console.log(part, partIndex, beforeString, 'search parts')

                        /* Compose end and start string */
                        let startString = beforeString.substr(beforeString.length - partialsToShow, beforeString.length);
                        // let startString = beforeString.length <= 100 ? beforeString : beforeString.substr(beforeString.length - partialsToShow, beforeString.length);
                        let endString   = afterString;

                        // if(startString.length <= 100 && partIndex > 1) startString = parts.slice(partIndex - 2, partIndex).join('');
                        
                        if(endString.length <= 100 && (partIndex + 4) < parts.length) {
                            const endStringParts = parts.slice(partIndex + 1, parts.length);

                            console.log('endStringParts', endStringParts)
                            
                            endString = endStringParts
                            .map((part) =>
                                part.match(regex)
                                ? `<mark>${part}</mark>`
                                : part
                            )
                            .join("");

                            endString = endString.length > 150 ? endString.substr(0, 150) : endString;
                        }

                        /* Get result position */
                        const resultPosition = startString.length < 100 ? 'topPosition' : endString.length < 100 ? 'bottomPosition' : ''

                        highlightedText = `<p class="partialResult ${resultPosition}">${startString}<mark>${part}</mark>${endString}</p>`;

                        /* Set up orignal content with highlight */
                     
                        const orignalContent = parts
                            .map((part) =>
                                part.match(regex)
                                ? `<mark>${part}</mark>`
                                : part
                            )
                            .join("");

                        /* Format paragraph */
                        /* Break perlines */
                        const lines = orignalContent.split('\n');

                        /* Format lines into paragraph */
                        let newFormat = lines.map(l => `<p>${l}</p>`).join('');

                        /* Change divs into p tag */
                        newFormat = newFormat.replace(/div>/gi, 'p>');

                        /* Check if container is ellipsis */
                        console.log('newFormat', cont)
                        if(!Array.from(cont.classList).includes('isEllipsis')) highlightedText = orignalContent;
                        else highlightedText = `${highlightedText} <div class="originContent">${newFormat}</div>`
                            
                    } else highlightedText = cont.innerHTML

                    cont.innerHTML = highlightedText;
                })

                 /* Highlight Metadata */
                highlightMetaData()
                
                callback();

                return;
            } 
            
            /* normal highlight */
            normalHighlight();

            

            callback();


        }

        /**
         * Remove highlight marker
         * @memberof Highlight
         * @function RemoveHighlight
         * @param {Array} elements Array of elements where the highlight will be removed
         * @param {Function} callback
         */

        static RemoveHighlight({elements = [], callback =() => {}} = {}) {
            elements.forEach(el => {
                el.innerHTML = el.innerHTML.replace(/<mark>/gi, '');
                el.innerHTML = el.innerHTML.replace(/<\/mark>/gi, '');
            })

            callback();
        }

    
}