<template>
	<div class="populartags">
		<h2>Popular Tags</h2>

		<ul>
			<template v-if="!loadedSections.includes('popularTags')">
				<li class="isLoading" v-for="n in 5" :key="n"></li>
			</template>

			<template v-else>
				<li v-for="tag in popularTags"
					@click="() => emit('tagClickTracking', tag)"
					:key="tag.id">{{ tag.tag_name }}</li>
			</template>

		</ul>
	</div>
</template>

<script setup>
/* ========= UTILS ===== */
import { defineProps, defineEmits } from 'vue'


/* ========= PROPS ====== */
defineProps({
	loadedSections: { type: Array, default: () => [] },
	popularTags: { type: Array, default: () => [] },
})

/* ========= EMITS  ====== */
const emit = defineEmits([
	'tagClickTracking'
])


</script>
