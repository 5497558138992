<template>
	<div class="quotetags">
		<div class="flex items-center mb-4 pr-3">
			<h1 class="mb-1 flex-1">Tags</h1>
			<m-clear-btn v-if="isEdit" :class="['dark:text-white', {'!text-orange' : isReorder}]" @click="() =>  isReorder = !isReorder">
				<quote-icon icon="numbered-list" class="!w-6 !h-6 !bg-transparent !text-inherit" />
			</m-clear-btn>
		</div>

		<template v-if="isLoading">
			<label>
				<span v-for="n in 5" :key="n" class="tagItem isLoading">

				</span>
			</label>
		</template>



		<label class="mb-6" v-else @click="() => emit('setFocusTagFld')" id="tagList" ref="tagPopList" for="tags" >
			<span v-for="tag in quote.tags" :key="tag.id"
				:data-tagId="tag.id"
				:class="['tagItem dark:!bg-gray-10 dark:!text-white flex items-center', { '!pr-0': isEdit }, { 'active': activeTag.id == tag.id }]">
				<m-clear-btn v-if="isReorder" class="!bg-transparent mr-2" >
					<quote-icon icon="arrow-pointing-out" class="!w-4 !h-4 rotate-45 !text-black !bg-transparent dark:!text-white" />	
				</m-clear-btn>
				
				{{ tag.tag_name }} <a v-if="isEdit" class="bg-transparent" @click.prevent="() => emit('removeTagItem', tag)"
					href=""><quote-icon class="!bg-transparent mx-2 w-4 !h-4 !text-black dark:!text-white" icon="x-mark" /></a>
			</span>

			<input  autofocus v-if="isEdit" id="tags" ref="tagFld" @blur="activeTag = {}" @keyup="(e) => emit('getDeleteTag', e)"
				v-model="searchTagStr" @input="() => emit('searchTagFilter')" :placeholder="quote?.tags?.length == 0 ? 'Add tag' : ''"
				type="text" class="form-control px-3 ignore-elements">

			<div  v-clickAway="{ id: 'tagList', triggerCallback: () => emit('resetSearchResult'), callback: () => emit('resetSearchResult') }"
				v-if="searchTagStr" :class="['popList ignore-elements', popPosition]" >

				<ul class="list-group" >
					<li v-if="!existingTag && searchTagStr"
						@click.prevent="$router.push({ name: 'newTag', query: { tagName: searchTagStr } })"
						class="list-group-item ">
						<a @click.prevent href="" class="flex items-center  ">
							<quote-icon class="text-orange dark:text-orange  !w-5 !h-5 mr-2"
								icon="plus-circle-fill"></quote-icon>
							{{ searchTagStr }}
						</a>
					</li>

					<li class="list-group-item" @click="() => emit('setNewTag', tag)" v-for="tag in tagList" :key="tag.id">
						{{ tag.tag_name }}
					</li>
				</ul>
			</div>
		</label>
	</div>
</template>

<script setup >
/* ========= UTILS ===== */
import Sortable from 'sortablejs'
import { defineProps, defineEmits, defineModel, onMounted, ref } from 'vue'

/* ========= PROPS ====== */
const props = defineProps({
	isLoading: {type : Boolean, default : () => false},
	quote: {type : Object, default : () => {}},
	isEdit: {type : Boolean, default : () => false},
	popPosition: {type : String, default : () => ''},
	existingTag: {type : Boolean, default : () => false},
	tagList: {type : Array, default : () => []},
})

/* ============ STATES ========= */
const isReorder =ref(false)

/* ============= EMITS ========= */
const emit = defineEmits([
	'setFocusTagFld',
	'removeTagItem',
	'getDeleteTag',
	'searchTagFilter',
	'resetSearchResult',
	'setNewTag'
])

/* ============= MODELS ======= */
const activeTag = defineModel('activeTag', { type: Object, default: () => {} })
const searchTagStr = defineModel('searchTagStr', { type: String, default: () => '' })
const newTagOrder = defineModel('newTagOrder', { type: Array, default: () => [] })



/* ============= METHODS ======= */

/**
 * Sortable instance
 */
const sortableIntance = () => {
	const tagList = document.getElementById('tagList')
	console.log('tagList', tagList)

	if(!props.isEdit)  return // if not in edit mode
	if(!tagList)  return // if tagList is not available
	new Sortable(tagList, {
		animation: 150,
		ghostClass: 'bg-gray-100',
		chosenClass: 'bg-gray-200',
		draggable: '.tagItem',
		filter: '.ignore-elements',
		onEnd: (e) => {
			const tagIds= Array.from(e.to.children)
				.filter(el => el.dataset.tagid)
				.map((el) => el.dataset.tagid)
			console.log('sortable end', tagIds)
			newTagOrder.value = tagIds
		}
	})
}

/**
 * On mounted
 */
onMounted(() => {
	// Sortable instance
	sortableIntance()
})
</script>
