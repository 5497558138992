<template>

    <div 
        @click="emit('click')"
        v-swipelistener="{callback : showactions, id : author?.id}"
        
        :class="['authoritem', 
            {'active' : isActive},
            ]">
        <div 
            class="authorpic">

            <img 
                v-if="author?.author_img"
                :src="FileUtils.fullPath(author?.author_img || '')" alt="">

            <span 
                v-else
                class="defaultImage">
                <quote-icon class="!w-4" icon="person"></quote-icon>
            </span>
        </div>

        <div class="authornameWrap flex-1">
            <span class="authorname">
                {{author?.first_name}} {{ author?.last_name }}
            </span>
        </div>

        
    </div>

</template>

<script setup>
/* ========= UTILS: ======== */
import FileUtils from '@/manie-utils/fileUtils';
import { defineEmits, defineProps } from 'vue';

/* ========= EMITS ========== */
const emit = defineEmits(['click', 'deleteAuthor'])

/* ========= PROPS ========== */
defineProps({
    showactions: {type : Function, default : () => {}},
    author     : {type : Object, default : () => {}},
    isActive : {type : Boolean, default : () => false},
    activeaction : {type : Number, default : () => null}
})
</script>