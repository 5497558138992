<template>
  <div
    v-show="!showApproval"
    refs="homeBody"
    @scroll="() => emit('fetchMoreQuoteStory')"
    class="homebody"
  >
    <div :key="reRender" class="container">
      <div class="quotelist">
        <template v-if="isLoading">
          <div v-for="n in 5" :key="n" class="quoteitem isLoading"></div>
        </template>

        <template v-else>
          <div
            v-for="(item, quoteIndex) in mergedQuoteStory"
            :key="item.id + `${item.story ? 'story' : 'quote'}` + quoteIndex"
            :class="[
              'quoteitem ',
              { activequote: isShow(item, 'class') },
              { showCard: showCardEffect(item) },
            ]"
          >
            <quote-story-card
              :key="reRender"
              @deletedFav="
                (id) =>
                  (snippetFavList = [
                    ...snippetFavList.filter((f) => f.id != id),
                  ])
              "
              :item="item"
            >
            </quote-story-card>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
/* ========= UTILS ===== */
import { defineProps, defineEmits, defineModel } from "vue";

/* ======== PROPS ====== */
defineProps({
  showApproval: { type: Boolean, default: () => true },
  reRender: { type: Number, default: () => 0 },
  isLoading: { type: Boolean, default: () => false },
  mergedQuoteStory: { type: Array, default: () => [] },
  isShow: { type: Function, default: () => {} },
  showCardEffect: { type: Function, default: () => {} },
});

/* ======== EMITS  ====== */
const emit = defineEmits([
  "toggleApproval",
  "toggleSearch",
  "getStoriesAndQuote",
  "FetchMoreQuoteStory",
]);

/* ======== MODELS ====== */
const snippetFavList = defineModel("snippetFavList", {
  type: Array,
  default: () => [],
});
</script>
