<template>
    <label class="custom-radio mr-2 items-center !flex">
        <input v-model="selected" @change="() => emit('change')" type="radio" name="sorting" 
        :value="value">
        <span class="checkmark"></span>
        <span class="pl-2"> <slot></slot> </span>
    </label>
</template>

<script setup>
/** ============== UTIlS ========= */
import { defineProps, defineModel, defineEmits } from 'vue';

/* =========== PROPS ======== */
defineProps({
    name : { type : String, default : ''},
    value : { type : String, default : ''}
})

/* =========== MODELS ======== */
const selected = defineModel('selected', {type : String, default : ''})

/* =========== EMITDS ======== */
const emit = defineEmits(['change'])

</script>