<template>
	<div :key="reRenderEditor + 'quotenotes'" class="quotenotes">
		<h1>Notes</h1>
		<div :class="['notemesssage', { 'isLoading': isLoading }]">

			<div v-if="!isLoading && isEdit" :contenteditable="true"
				v-content-editor="{ initialContent: note, callback: (string) => emit('updateNote', string), placeholder: 'Write note...' }"
				class="form-control ">

			</div>

			<div class="form-control" v-else>

				<div v-html="note ? note : 'No notes'"
					class="form-control w-full min-h-10 !px-0 !text-gray-9 resize-none">
				</div>
			</div>


		</div>
	</div>
</template>

<script setup >
/* ======== UTILS ====== */
import { defineProps, defineEmits } from 'vue'

/* ========== PROPS ======== */
defineProps({
	isLoading: {type : Boolean, default : () => false},
	note: {type : String, default : () => ''},
	reRenderEditor : {type : Number, default : () => 0},
	isEdit : {type : Boolean, default : () => false}
})

/* ========== EMITS ======== */
const emit = defineEmits([
	'updateNote',
])

</script>
