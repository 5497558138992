<template>
	<label class="custom-checkbox !flex items-center text-sm !pl-0">
		<input v-model="isChecked" name="checkbox-1" :value="true" :unchecked-value="false" type="checkbox">
		<span class="checkmark flex-[0_0_1.5rem] mr-2 !relative"></span> {{ label }}
	</label>
</template>

<script setup>
/* ========= UTILS ===== */
import { defineProps, defineModel } from 'vue'
/* ========= PROPS ====== */
defineProps(
	{ 
		label: {type : String, default : ''},
	}
)

/* ========= MODELS ===== */
const isChecked = defineModel('isChecked', {type : Boolean, default : false})
</script>
