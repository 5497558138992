<template>
    <div 
        v-if="user"
        :class="['footerbar transition-[bottom] duration-500 -bottom-[6.3rem]', {'!bottom-0' : $route.meta.page != 'users'}]">
        <div class="container">
       
            <div 
                :class="['footeraction', $route.meta.viewType]">
               
                <!-- User Menu -->
                    <div
                        :class="[
                            'menuitem publicfbtn',
                            { active: $route.meta.viewType == 'public' },
                        ]"
                    >
                        <a
                            @click.prevent="goTo('library')"
                            href=""
                        >
                            <quote-icon class="!w-auto" icon="library" />
                        </a>
                    </div>

                    <div
                        :class="[
                            'menuitem',
                            { active: $route.meta.viewType == 'personal' },
                        ]"
                    >
                        <a
                            @click.prevent="goTo('home')"
                            href=""
                        >
                            <quote-icon class="!w-auto" icon="quote" />
                        </a>
                    </div>

                    <div class="verticalDivider"></div>

                    <div
                        v-if="user.user_menu_favorites && !user?.is_admin"
                        :class="[
                            'menuitem',
                            { active: $route.meta.page == 'favorites' },
                        ]"
                    >
                        <a
                            @click.prevent="goTo('favorites')"
                            href=""
                        >
                            <quote-icon class="!w-19 mt-1" icon="heart-fill" />
                        </a>
                    </div>


    
                    <div
                        v-if="user.user_menu_authors"
                        :class="[
                            'menuitem',
                            { active: $route.meta.page == 'authors' },
                        ]"
                    >
                        <a
                            @click.prevent="goTo('authors')"
                            href=""
                        >
                            <quote-icon class="!w-auto" icon="person" />
                        </a>
                    </div>
                    
                    <div
                        v-if="user?.is_admin"
                        :class="[
                            'menuitem !w-40',
                            { active: $route.meta.page == 'nonauthors' },
                        ]"
                    >
                        <a
                            class="!w-[3rem]"
                            @click.prevent="goTo('nonauthors')"
                            href=""
                        >
                            <quote-icon class="!w-10 !h-10 nonauthoicon" icon="person-solid-x" />
                        </a>
                    </div>
    
                    <div
                        v-if="user.user_menu_sources"
                        :class="[
                            'menuitem',
                            { active: $route.meta.page == 'sources' },
                        ]"
                    >
                        <a
                            @click.prevent="goTo('sources')"
                            href=""
                            ><quote-icon class="!w-auto" icon="book-up"
                        /></a>
                    </div>
    
                    <div
                        v-if="user.user_menu_tags"
                        :class="[
                            'menuitem',
                            { active: $route.meta.page == 'tags' },
                        ]"
                    >
                        <a @click.prevent="goTo('tags')" href="">
                            <quote-icon class="!w-auto" icon="tag" />
                        </a>
                    </div>

            </div>
        </div>
    </div>
</template>

<script>
/* Mixins */
import { settingsMixins } from "@/mixins/settingsMixins";

export default {
    mixins: [settingsMixins],
    data() {
        return {
            showmenu   : false,
        };
    },
    computed : {
        /* View mode */
        viewAsAdmin () {
            return this.settings?.viewAsAdmin ?? false;
        }
    },
    methods : {
        /* go to page base on viewtype */
        goTo(route) {
            let TheRoute = route;

            if(this.$route.meta.viewType == 'public') {
                switch (route) {
                    case 'authors':
                            TheRoute = 'libraryAuthors';
                        break;

                    case 'sources':
                            TheRoute = 'librarySources';
                        break;
                    
                    case 'tags':
                            TheRoute = 'libraryTags';
                        break;

                    case 'favorites':
                            TheRoute = 'libraryFavorites';
                        break;
                
                    default:
                        break;
                }
            }

            this.$router.push({ name: TheRoute })
        }
    },
    beforeMount() {
        /* Get  view as */
        this.settings.viewAsAdmin = this.getViewAs();
    },
};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.footerbar {
    background-color: #fff;
    border-top      : 1px solid darken($color: $lightgray, $amount: 5);
    padding-top     : 15px;
    padding-bottom  : 15px;
    position        : fixed;
    left            : 0;
    right           : 0;
    z-index         : 700;
    height          : 90px;

    svg {
        font-size: 25px;
    }

    .container {
        height: 100%;
    }

    .footeraction {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        z-index        : 200;
        // padding-inline : 15px;
        height: 100%;

        .verticalDivider {
            border-right: 1px solid $lightgray3;
            height      : calc(100% - 30px);
        }

        .menuitem {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.active {
                a,
                .dropdown {
                    background-color: lighten($orange, 35);
                    color: $orange;
                    border-radius: 8px;
                }

                // svg {
                //     fill: $orange;
                // }

                // &.publicfbtn {
                //     a, .dropdown {
                //         background-color: $blue4;
                //         color           : $blue;
                //     }

                //     .chev, svg {
                //         fill : $blue;
                //     }
                // }

               

            }

            &.dpmenu {
                border-right: 1px solid $lightgray3;
                padding-inline: 10px;

                a {
                    width: 62px;
                    height: 45px;
                    display: flex;
                    align-items: center;

                    & > span {
                        flex: 1;

                        &:last-child {
                            text-align: right;
                        }
                    }
                }
            }

            a {
                color          : $lightgray5;
                padding        : 0 10px;
                width          : 3rem;
                overflow       : clip;
                display        : flex;
                justify-content: center;
                align-items    : center;

                .chev {
                    svg {
                        margin-left: 5px;
                        font-size: 10px;
                    }
                }
            }

            &:not(.dpmenu) {
                a {
                    height: 45px;

                    svg {
                        height: 100%;
                    }


                    svg:not(.nonauthoicon svg) {
                        width : 31px;
                        height: 31px;
                    }

                   
                    .quoteicon {
                        height: 100%;
                        align-items: center;
                    }
                }

                .library {
                    svg {
                        width : 26px;
                        height: 26px;
                    }
                }

                .book-up {
                    position: relative;

                    svg {
                        width: 28px;
                        height: 28px;
                    }
                }

                .person {
                    @extend .book-up;
                }

                .tag {
                    @extend .book-up;
                }

                .star {
                    svg {
                        position: relative;
                        top: -2px;
                    }
                }

                .heart-fill {
                    width : 28px;
                    height: 28px;
                }
            }

            .dropdown {
                .dropdown-toggle {
                    background-color: transparent;
                    color: inherit;
                    border: none;
                    padding: 0;
                    display: flex;
                    align-items: center;

                    &::after {
                        display: none;
                    }

                    .chev {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .list-group {
        border-bottom: 1px solid darken($color: $lightgray, $amount: 5);
        position: fixed;
        bottom: 90px;
        left: 0;
        right: 0;
        width: 100%;
        padding-inline: 15px;
        padding-block: 15px;
        background-color: white;

        .list-group-item {
            border          : none;
            text-align      : center;
            font-size       : 16px;
            display         : flex;
            align-items     : center;
            justify-content : center;
            cursor          : pointer;
            background-color: transparent;

            svg {
                margin-right: 10px;
            }

            &:hover,
            &.active {
                background-color: $lightgray7;
                color: $orange;

                * {
                    color: inherit;
                }
            }


            &.libraryMenu {
                &:hover, &.active {
                    // background-color: $blue4 ;
                    color           : $blue ;

                    * {
                        color : inherit;
                    }
                }
            }
        }
    }
}
</style>
