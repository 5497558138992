<template>
	<div class="relative">
		<input 
			:readonly="!isEdit" 
			@input="() => emit('searching')"
			v-model="searchString" 
			placeholder="-" 
			type="text" 
			class="form-control w-full">


		<div v-if="showResult" class="popList">

			<div @click="() => emit('close')" class="fixed w-screen h-screen top-0 left-0 z-10"></div>

			<ul class="list-group relative z-20">
				<li class="list-group-item" @click="() => emit('selected', item)" v-for="(item, i) in resultList"
					:key="i">
					{{ item.label }}
				</li>
			</ul>

		</div>
	</div>
</template>

<script setup>
/* ========== UTILS ======== */
import { defineProps, defineModel, defineEmits } from 'vue'


/* ========== PROPS ========= */
defineProps({
	isEdit: {type: Boolean, default: true},
	resultList: {type: Array, default : () => []},
	showResult	: {type: Boolean, default : false}
})

/* ========== EMITS ========= */
const emit = defineEmits(['searching', 'selected', 'close'])

/* ========== MODELS ========= */
const searchString = defineModel('searchString', {type: String, default: ''})

</script>
