<template>
	<m-form-group label="Author type">
		<div class="flex justify-between flex-wrap dark:text-white">
			<m-clear-btn v-for="authorType in subAuthorType" :key="authorType"
				class="text-xs w-fit h-fit px-4 py-1 border border-orange rounded-md mb-3 "
				:class="[{'bg-orange text-white': selectedAuthorType == authorType}]"
				@click="() => (selectedAuthorType = authorType, emit('change', authorType))">
				{{ authorType }}
			</m-clear-btn>

		</div>
	</m-form-group>
</template>

<script setup >
/* ========= UTILS ===== */
import { subAuthorType } from '@/manie-utils/staticVariables';
import { defineModel, defineEmits } from 'vue'

/* =========== EMITS ========= */
const emit = defineEmits(['change'])

/* ========= MODEL  ====== */
const selectedAuthorType = defineModel('selectedAuthorType', {type : String, default : ''})
</script>
