<template>
  <div class="header">
    <div class="container">
      <div class="grid grid-cols-2 gap-4">
        <div>
          <div class="headeraction left">
            <a
              href=""
              @click.prevent="$router.push({ name: 'settings' })"
              class="headeractionitem"
            >
              <quote-icon icon="gear" />
            </a>

            <div>
              <h1>
                {{
                  $route.meta.page == "favorites"
                    ? "Favorites"
                    : "Personal Quotes"
                }}
              </h1>
              <small :class="['pl-3']">{{ snippetCount ?? 0 }} Quotes</small>
            </div>
          </div>
        </div>

        <div>
          <div class="headeraction right items-center h-full">
            <a
              v-show="$route.name == 'home' && user?.is_admin"
              @click.prevent="() => emit('toggleApproval')"
              href=""
              :class="['headeractionitem', { active: showApproval }]"
            >
              <quote-icon class="!w-4" icon="check-list" />

              <span class="notifBadge" v-if="totalApprovalCount > 0">{{
                totalApprovalCount
              }}</span>
            </a>
            <a
              @click.prevent
              id="showFilter"
              href=""
              :class="['headeractionitem', { active: showFilterPop }]"
            >
              <quote-icon class="!w-4" icon="filter" />
            </a>

            <a
              @click.prevent="() => emit('toggleSearch')"
              href=""
              :class="['headeractionitem', { active: showSearch }]"
            >
              <quote-icon class="!w-4" icon="search" />
            </a>

            <a
              @click.prevent="
                $router.push({
                  name: 'newquote',
                  query: { story: false, type: 'quote' },
                })
              "
              href=""
              :class="['headeractionitem addbtn', { active: showNewQuoteOpt }]"
            >
              <quote-icon class="!w-4" icon="plus" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-clickAway="{
        id: 'showFilter',
        triggerCallback: (state) => {
          showFilterPop = state;
        },
        callback: (state) => (showFilterPop = state),
      }"
      :class="[
        'filterPopWrap slide  !py-0',
        { 'slide-in !py-7': showFilterPop },
      ]"
    >
      <div class="form-group">
        <legend>Filter by:</legend>

        <div
          v-for="cat in snippetTypes"
          :key="cat"
          class="flex items-center text-base mb-5"
        >
          <label class="custom-checkbox capitalize"
            >{{ cat == "story" ? "storie" : cat }}s
            <input
              v-model="filterBy.type"
              :value="cat"
              unchecked-value=""
              type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="form mt-6">
        <legend>Sort by:</legend>

        <div class="flex items-center text-base mb-5">
          <label class="custom-radio mr-2">
            <input
              v-model="filterBy.sortBy"
              @change="() => emit('getStoriesAndQuote')"
              type="radio"
              name="sorting"
              value="desc"
            />
            <span class="checkmark"></span>
            <span class="pl-2">Newest to Oldest</span>
          </label>
        </div>
        <div class="flex items-center text-base mb-5">
          <label class="custom-radio mr-2">
            <input
              v-model="filterBy.sortBy"
              @change="() => emit('getStoriesAndQuote')"
              type="radio"
              name="sorting"
              value="asc"
            />
            <span class="checkmark"></span>
            <span class="pl-2">Oldest to Newest </span>
          </label>
        </div>
        <div class="flex items-center text-base mb-5">
          <label class="custom-radio mr-2">
            <input
              v-model="filterBy.sortBy"
              @change="() => emit('getStoriesAndQuote')"
              type="radio"
              name="sorting"
              value="alpha"
            />
            <span class="checkmark"></span>
            <span class="pl-2">Alphabetical </span>
          </label>
        </div>
      </div>
    </div>

    <div
      v-clickAway="{
        id: 'addQuoteStory',
        triggerCallback: (state) => {
          showNewQuoteOpt = state;
        },
        callback: (state) => (showNewQuoteOpt = state),
      }"
      :class="[
        'newQuoteOpt slide absolute w-full bg-white dark:bg-gray-10 top-[4.2rem] left-0 !py-0 !border-0',
        { 'slide-in !py-7 !border-1': showNewQuoteOpt },
      ]"
    >
      <template v-for="type in snippetTypes" :key="type">
        <div
          @click="
            $router.push({ name: 'newquote', query: { story: false, type } })
          "
          class="newQuoteOptItem capitalize"
        >
          <quote-icon v-if="type == 'quote'" class="!w-9" icon="quote" />
          <quote-icon v-if="type == 'story'" class="!w-9" icon="book" />
          <quote-icon v-if="type == 'poem'" class="!w-9" icon="poem" />
          <quote-icon v-if="type == 'song'" class="!w-9" icon="song" />
          <quote-icon
            v-if="type == 'scripture'"
            class="!w-8"
            icon="scripture"
          />

          {{ type }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
/* ======== UTILS ====== */
import { defineProps, defineEmits, defineModel } from "vue";

/* ========== PROPS ======== */
defineProps({
  showApproval: {
    type: Boolean,
    default: () => true,
  },
  user: {
    type: Object,
    default: () => {},
  },
  showSearch: {
    type: Boolean,
    default: () => false,
  },
  snippetTypes: {
    type: Array,
    default: () => [],
  },
  snippetCount: {
	type: Number,
	default: () => 0,
  },
  totalApprovalCount: {
	type: Number,
	default: () => 0,
  },
  
});

/* ========== EMITS ======== */
const emit = defineEmits([
  "toggleApproval",
  "toggleSearch",
  "getStoriesAndQuote",
]);

/* ========== MODELS ======== */


const showFilterPop = defineModel("showFilterPop", {
  required: true,
});
const showNewQuoteOpt = defineModel("showNewQuoteOpt", {
  required: true,
});
const filterBy = defineModel("filterBy", {
  required: true,
});
</script>
