<template>
    <button  @click="() => emit('click')"><slot></slot></button>
</template>

<script setup>
/* ======= UTILS ====== */
import { defineEmits } from 'vue';


/* ========== EMITS ====== */
const emit = defineEmits(['click'])
</script>