<template>
    <div class="pt-4">
        <div class="flex items-center text-orange" @click="() => emit('back')">
            <m-back-btn :callback="() => emit('back')" /> Authors
        </div>

        <!-- <div class="my-4">
            <input type="text" v-model="newQuote.author_by" placeholder="Author" class="form-control w-full" />
        </div> -->

        <div class="my-4">
            {{ newQuote?.author_type }} {{ authorReferenceName }}
        </div>

        <m-newquote-author-item 
            v-for="author in localAuthors" 
            @click="() => setReferenceAuthor(author)"
            :key="author?.id" 
            :author="author" />
    </div>
</template>

<script setup>
/* ============ UTILS ====== */
import store from '@/store';
import { computed, defineProps, defineEmits } from 'vue';

/* ============ PROPS ====== */
const props = defineProps({
    authors : {type : Array, default : () => []}
})

/* ============ EMITS ====== */
const emit = defineEmits(['back'])

/* ============ STATES ====== */

/* ============ COMPUTED ====== */

/**
 * New quote Object
 */
const  newQuote = computed({
    get() {
        return store.state.newQuote
    },
    set(quote) {
        store.commitI('updateNewQuote', quote)
    }
})

/**
 * Get author reference name
 */
const authorReferenceName = computed(() => {
    let author = newQuote.value?.referenceAuthorName || '';
    return author;
})

/**
 * Remove selected main author from the list
 */
const localAuthors = computed(() => {
    let list = [...props.authors].filter(auth => auth.id != newQuote.value?.author_id) 
    return list;
})

/* ============ METHODS ====== */
/**
 * Set reference author
 * @param {Object} author 
 */
const setReferenceAuthor = (author) => {
    newQuote.value.author_by = author;
    newQuote.value.referenceAuthorName = `${author.first_name} ${author.last_name}`
}
</script>