<template>
	<div class="">
		<h1 class="text-2xl font-semibold mb-4 px-2">{{title}} <small class="font-normal text-sm">({{ isLoading ? 0 : totalCount }})</small></h1>

		<div class="">
			<template v-if="isLoading">
				<div v-for="n in 15" :key="n" class="">
					<div class="text-gray-13 px-5 mb-2.5 text-sm uppercase">
						&nbsp;
					</div>

					<div class="bg-white dark:bg-gray-10 dark:text-white p-5 rounded-xl isLoading">
						<div class="text-sm  py-3 flex items-center min-h-[40px] isLoading">
							&nbsp;
						</div>
					</div>
				</div>
			</template>

			<template v-else>

				<div v-for="group in list" :key="group.group" class=" mb-5">
					<div class="text-gray-13 px-5 mb-2.5 text-sm uppercase">
						{{ group.group == '1' ? '' : group.group }}
					</div>

					<div class="bg-white dark:bg-gray-10 dark:text-white p-5 rounded-xl divide-y divide-gray-5 dark:divide-gray-14">
						<div v-for="item in group.children"
							@click="() => emit('click', item)"
							:key="item.id" class="text-sm  py-3 flex items-center min-h-[40px]">
							<div class="flex-1 font-sm">
								{{ item.name }}
							</div>

							<div v-if="item.img" class="w-7 h-7 rounded-full overflow-clip bg-cover bg-center">
								<img class="w-full h-full object-contain" :src="awsUrl + item.img" alt="">
							</div>
						</div>
					</div>
				</div>

				<template v-if="fetchingMore">
					<div v-for="n in 5" :key="n + 'fetching'" class="taggroup">
						<div class="taggroupletter">
						</div>

						<div class="taglistinner isLoading">
						</div>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>

<script setup >
/* ========= UTILS ===== */
import { defineProps, defineEmits } from 'vue'

/* ========= PROPS ====== */
defineProps(
	{ 
		title: {type : String, default : () => ''},
		isLoading: {type : Boolean, default : () => false},
		totalCount: {type : Number, default : () => 0},	
		list: {type : Array, default : () => []},
		fetchingMore: {type : Boolean, default : () => false}
	}
)

/* ========= EMITS  ====== */
const emit = defineEmits([
	'click'
])

/* ========= STATES ====== */
const awsUrl = process.env.VUE_APP_AWS_URL
</script>
