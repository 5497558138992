import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Vue.config.productionTip = false

/**
 * Global declerations
 */

/* Components */
import quoteIcons from '@/quoteIcons/quote-icons';
import dropdown from '@/components/dropdown';
import Modal from '@/components/modal'
import searchInput from '@/components/searchinput'
import DropZone from '@/components/fileDropZone'
import DropZonePop from '@/components/fileDropZonePop'
import mButtonSecondary from './components/mButtonSecondary.vue'
import backBtn from './components/backBtn.vue'
import clearBtn from './components/clearBtn.vue'
import tablePagination from './components/tablePagination.vue'
import mHomeHeader from './components/m-home-header.vue'
import mHomeSnippets from './components/m-home-snippets.vue'
import quoteStoryCard from './components/quoteStoryCard.vue'
import mMetaDataListHeader from './components/m-meta-data-list-header.vue'
import mMetaDataList from './components/m-meta-data-list.vue'
import mLibraryPopularTags from './components/m-library-popular-tags.vue'
import mLibraryNewSources from './components/m-library-new-sources.vue'
import mLibraryTrendingSources from './components/m-library-trending-sources.vue'
import mInnerHeader from './components/m-inner-header.vue'
import mEditSnippetCard from './components/m-edit-snippet-card.vue'
import mEditSnippetMetadataTable from './components/m-edit-snippet-metadata-table.vue'
import mEditSnippetsAttachment from './components/m-edit-snippets-attachment.vue'
import mEditSnippetTags from './components/m-edit-snippet-tags.vue'
import mEditSnippetNote from './components/m-edit-snippet-note.vue'
import mInputSearchPop from './components/m-input-search-pop.vue'
import inputRadio from './components/inputRadio.vue'
import newquotestep25 from './components/newquotestep2-5.vue'
import newSnippetAuthorItem from './components/newSnippetAuthorItem.vue'
import mFormGroup from './components/m-form-group.vue'
import mCheckBox from './components/m-check-box.vue'
import mAuthorTypesBtns from './components/m-author-types-btns.vue'

// Directive
import {swipelistener, clickAway, dragScroll, isVisible, contentEditor, paragraphFormat, autowidth, Masking} from './directives';
// import VueMask from 'v-mask'
// Plugins
import "./plugins";
import { VueQueryPlugin } from '@tanstack/vue-query'

// import VueCrontab from 'vue-crontab'


/* Fitlers */
import './filters';

const app = createApp(App)
  .use(store)
  .use(router)
  // .use(VueCrontab)

/* Component registrations */
app.component('quote-icon', quoteIcons);
app.component('dropdown', dropdown);
app.component('modal', Modal);
app.component('search', searchInput);
app.component('m-search', searchInput);
app.component('file-drop-zone', DropZone);
app.component('file-drop-zone-pop', DropZonePop);
app.component('m-dropdown', dropdown);
app.component('m-secondary-btn', mButtonSecondary);
app.component('m-back-btn', backBtn)
app.component('m-clear-btn', clearBtn)
app.component('m-table-pagination', tablePagination)
app.component('m-home-header', mHomeHeader)
app.component('m-home-snippets', mHomeSnippets)
app.component('quote-story-card', quoteStoryCard)
app.component('m-meta-data-list-header', mMetaDataListHeader)
app.component('m-meta-data-list', mMetaDataList)
app.component('m-library-popular-tags', mLibraryPopularTags)
app.component('m-library-new-sources', mLibraryNewSources)
app.component('m-library-trending-sources', mLibraryTrendingSources)
app.component('m-inner-header', mInnerHeader)
app.component('m-edit-snippet-card', mEditSnippetCard)
app.component('m-edit-snippet-metadata-table', mEditSnippetMetadataTable)
app.component('m-edit-snippets-attachment', mEditSnippetsAttachment)
app.component('m-edit-snippet-tags', mEditSnippetTags)
app.component('m-edit-snippet-note', mEditSnippetNote)
app.component('m-input-search-pop', mInputSearchPop)
app.component('m-input-radio', inputRadio)
app.component('m-newquote-2-5', newquotestep25)
app.component('m-newquote-author-item', newSnippetAuthorItem)
app.component('m-form-group', mFormGroup)
app.component('m-check-box', mCheckBox)
app.component('m-author-types-btns', mAuthorTypesBtns)

/* Directive registration */
app.directive('swipelistener', swipelistener);
app.directive('clickAway', clickAway);
app.directive('drag-scroll', dragScroll);
app.directive('isVisible', isVisible);
app.directive('content-editor', contentEditor);
app.directive('paragraph-format', paragraphFormat);
app.directive('autowidth', autowidth);
// app.directive('mask', VueMask);
app.directive('mask', Masking);

/* Plugins */
app.use(VueQueryPlugin)


app.mount('#app')


