<template>
     <m-clear-btn
        @click="() => backBtn()"
        class="flex items-center text-base font-semibold text-orange"
        >
            <quote-icon class="w-5 mr-1" icon="chevron-left"></quote-icon> <slot> </slot>
        </m-clear-btn>
</template>

<script setup>
/* ======= UTILS ====== */
import router from '@/router';
import { defineProps } from 'vue';

/* ============ PROPs ========== */
const props = defineProps({
    callback: {type : Function, default : () => null}
})

/* ============ METHODS ========== */
/**
 * Back btn callback
 */
const backBtn = () => {
    console.log('props.callback', props.callback)

    if(props.callback) props.callback()
    else router.back()
}
</script>