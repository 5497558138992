<template>
	<div ref="itemCard" class="quoteWrap">
		<div :class="['quotecontent', { 'isLoading': isLoading }]">
			<div class="quoteheader">
				<div class="grid grid-cols-12">
					<div class="col-span-6">
						<quote-icon class="!w-5" v-if="quote?.s_category == 'story'" icon="book" />
						<quote-icon class="!w-5 !h-5 text-violet" v-if="quote?.s_category == 'poem'" icon="poem" />
						<quote-icon class="!w-5" v-if="quote?.s_category == 'quote'" icon="quote" />
						<quote-icon class="!w-5 !h-5 text-green" v-if="quote?.s_category == 'song'" icon="song" />
					</div>

					<div class="col-span-6">
						<div class="quoteaction">
							<a v-if="!user?.is_admin" href="" @click.prevent="() => emit('toggleFav')"
								:class="['fav', { 'active': quote.isFav }]">
								<quote-icon class="!w-5" v-if="quote.isFav" icon="heart-fill"></quote-icon>
								<quote-icon class="!w-6" v-else icon="heart"></quote-icon>
							</a>

							<a href="" @click.prevent="() => emit('copyClipboard', {string: $refs.itemCard.innerText})" class="dup">
								<quote-icon class="!w-5" v-if="copiedContent" icon="check-circle">
								</quote-icon>

								<quote-icon class="!w-5" v-else icon="subtract"></quote-icon>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div class="qoutebody">
				<div :key="reRenderEditor + 'quote' + quote.id" v-if="quote.id" v-content-editor="{
					initialContent: quoteContent,
					inlineTool: true,
					readonly: isEdit ? false : true,
					placeholder: quote.story ? 'Write Story' : 'Write Quote',
					callback: (string) => quoteContent = string
				}" class="form-control">

				</div>

			</div>

			<div class="quotefooter">
				<div class="grid grid-cols-12 gap-4">

					<div class="col-span-11">
						<div class="quoteauthor">
							<div v-if="quote.author" class="authorpic min-w-5">
								<template v-if="quote?.author?.author_img">
									<img :src="awsURL + quote.author.author_img" alt="">
								</template>
								<template v-else>
									<quote-icon icon="person-circle" />
								</template>
							</div>

							<div class="authorinfo">
								<div @click="() => emit('viewAuthor')" class="authorname"> 
									{{ authorName }} <span class="text-gray-15 text-sm font-normal" v-if="authorReference">{{ authorReference }}</span>
								</div>
								<div class="quotemeta">
									<a @click.prevent="() => emit('viewSource')" href="">
										{{ GetCompilationName(quote.source.containers) }}
										{{ quote.source.source_name }}{{ source_subtitle ? `,
										${source_subtitle}` : ''}}{{ getQuoteStoryPage(quote) }}
										{{ formatDate(quote?.s_date || '', {
											format:
										displayDateFormat})}}
									</a>
								</div>
								<div class="quotetags">
									<span v-for="tag in quote.tags" :key="tag.id" class="tag">
										{{ tag.tag_name }}
									</span>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script setup >
/* ========= UTILS ===== */
import { defineProps, defineEmits, defineModel, computed } from 'vue'

/* ========= PROPS ====== */
const props = defineProps(
	{
		isLoading: {type : Boolean, default : () => false},
		quote: {type : Object, default : () => {}},
		user: {type : Object, default : () => {}},
		copiedContent: {type : Boolean, default : () => false},
		isEdit: {type : Boolean, default : () => false},
		GetCompilationName: {type : Function, default : () => {}},
		source_subtitle: {type : String, default : () => ''},
		getQuoteStoryPage: {type : Function, default : () => {}},
		formatDate: {type : Function, default : () => {}},
		displayDateFormat: {type : String, default : () => ''},
		reRenderEditor : {type : Number, default : () => 0},
		awsURL : {type : String, default : () => ''},
		referenceAuthor : {type : String, default : () => ''},
	}
)

/* ============= EMITS ========= */
const emit = defineEmits([
	'toggleFav',
	'copyClipboard',
	'viewAuthor',
	'viewSource'
])

/* ========= MODELS ===== */
const quoteContent = defineModel('quoteContent', { type: String, default: () => '' })


/* ========= COMPUTED ===== */
/**
 * Author name
 */
const authorName = computed(() => {
	if(props.quote?.author_type == 'attributed to' && props.quote?.author?.id == props.quote?.author_by?.id) return null;
	return `${props.quote?.author?.first_name || ''} ${props.quote?.author?.last_name || ''}`
})	

/**
 * Rerefence author
 */
const authorReference = computed(() => {	
	return props.referenceAuthor ? `(${props.quote.author_type} ${props.referenceAuthor})` : ''
})


</script>
