<template>
	<div class="bg-gray-6 sticky top-0 z-50 py-4 dark:bg-gray-12">
		<div class="container">
			<div class="grid grid-cols-2 gap-4">
				<div>
					<div class="flex items-center h-10 ">
					</div>
				</div>

				<div>
					<div class="flex items-center h-10 justify-end dark:text-white">

						<a @click.prevent="() => emit('toggleSearchFilter')" href=""
							:class="['w-10 min-w-10 flex justify-center items-center', { 'active': showSearch }]">
							<quote-icon class="!w-4 !h-4" icon="search" />
						</a>

						<a v-if="$route.meta.viewType == 'personal'" @click.prevent="() => emit('addNew')"
							href="" class="w-10 min-w-10 addbtn flex justify-center items-center">
							<quote-icon class="!w-4 !h-4" icon="plus" />
						</a>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup >
/* ========= UTILS ===== */
import { defineProps, defineEmits } from 'vue'

/* ========= PROPS ====== */
defineProps({ 
	showSearch: {type : Boolean, default : () => false}
})

/* ========= EMITS  ====== */
const emit = defineEmits([
	'toggleSearchFilter',
	'addNew'
])


</script>
