<template>
<div class="homeview">
    <m-home-header 
        @toggleApproval="toggleApproval" 
        @getStoriesAndQuote="getStoriesAndQuote" 
        @toggleSearch="toggleSearch" 
        :showApproval="showApproval" 
        :user="user" 
        :showSearch="showSearch" 
        :snippetTypes="snippetTypes" 
        :snippetCount="snippetCount" 
        :totalApprovalCount="totalApprovalCount" 
        v-model:filterBy="filterBy" 
        v-model:showFilterPop="showFilterPop" 
        v-model:showNewQuoteOpt="showNewQuoteOpt" />

    <div class="container mt-5">
        <search :showSearch="showSearch" :autoFocus="true" v-model:searchString="searchStr"  @input="() => {isSearching = true; processingHighlight = true}" />
    </div>

    <div v-show="showApproval">
        <approval-list 
            v-model:totalApprovalCount="totalApprovalCount" 
            :processingHighlightLocal="processingHighlight" 
            :searchStr="searchStr" 
            :filterBy="filterBy" ></approval-list>
    </div>

    <m-home-snippets 
        v-if="!showApproval"
        :fetchMoreQuoteStory="fetchMoreQuoteStory" 
        :showApproval="showApproval" 
        :reRender="reRender" 
        :isLoading="isLoading" 
        :mergedQuoteStory="mergedQuoteStory" 
        :isShow="isShow" 
        :showCardEffect="showCardEffect" 
        v-model:snippetFavList="snippetFavList" />

</div>
</template>

<script>
/* Mixins */
import { quotesMixins} from '@/mixins/quotesMixins'
import { storyMixins} from '@/mixins/storyMixins';
import { utilsMixins } from '@/mixins/utilsMixins';
import { settingsMixins } from '@/mixins/settingsMixins';
// import StoryQuoteMixins from '@/mixins/quoteStoryMixins';
import {favoriteMixins} from '@/mixins/favoriteMixins';
import {PoemMixins} from '@/mixins/poemMixins';
import {SongMixins} from '@/mixins/songMixins';

/* Utils */
// import { useQuery } from '@tanstack/vue-query'

// import StringParse from '@/manie-utils/stringParsing';


/* IDB */
import CombinedSnipetsApis from '@/manie-utils/idb/apis/combinedSnipetsListApis'


/* Components */
// import deleteQuote from '@/components/deleteQuote'
// import deleteStory from '@/components/deleteStory';
import approvalList from '@/components/approvalList';

/* Helpers */
import Helper from '@/helpers/helpers';

/* Plugins */
import _ from 'lodash';
// import axios from 'axios';

export default {
    name: "Home",
    mixins: [quotesMixins, storyMixins, utilsMixins, settingsMixins, favoriteMixins, PoemMixins, SongMixins],
    components : {
    // 'delete-quote'    : deleteQuote,
    // 'delete-story'    : deleteStory,
    "approval-list": approvalList,
},
    
    data() {
        return {
            awsURL         : process.env.VUE_APP_AWS_URL,
            activeaction   : null,
            currQuotePage  : null,
            quoteList      : [],
            showNewQuoteOpt: false,
            storyList      : [],
            currStoryPage  : null,
            showFilterPop  : false,
            showSearch     : false,
            snippetTypes   : ['quote', 'story', 'poem', 'song', 'scripture'],
            filterBy       : {
                type  : ['quote', 'story', 'poem', 'song', 'scripture'],
                sortBy: 'desc'
            },
            searchStr                    : '',
            copiedContent                : null,
            showNote                     : null,
            privateQList                 : [],
            currentPrivateQlist          : null,
            privateSList                 : [],
            currentPrivateSlist          : null,
            isLoading                    : true,
            attachmentUrl                : null,
            reRender                     : Math.random(),
            fetching                     : false,
            publishedQuotes              : {data : []},
            publishedStories             : {data : []},
            approvalQuotes               : {},
            approvalStories              : {},
            searchList                   : [],
            totalQuotes                  : {},
            totalStories                 : {},
            totalPubQuotes               : {},
            totalPubStories              : {},
            fetchingQuoteStories         : [],
            combinedQuotes               : {},
            combinedStories              : {},
            currentPageCombined          : 1,
            processingHighlight          : false,
            isSearching                  : false,
            combinedQuotesStories        : {},
            favoritedQuotes              : [],
            favoritedStories             : [],
            combinedFavoritedQuoteStories: {favoriteQuotes : [], favoriteStories : []},
            localQuotesStories           : [],
            updatedItem                  : [],
            combinedPoems                : {data : []},
            combinedSongs                : {data : []},
            snippetsList                 : [],
            snippetFavList               : [],
            totalApprovalCount           : 0,
            userSnippetTotal             : 0
        }
    },
    watch: {
       
        /* Watch filterby and trigger functions */
        filterBy : {
            handler() {
                /* Ellepsis */
                this.textOverFlow('.quoteitem .qoutebody');

                  /* Highlight */
                this.startHighlight(this.searchStr);
            },
            deep : true
        },
        /* Watch feching qutoe stories flag to disable loader and initiate ellipsis */
        fetchingQuoteStories(fetching) {
            if(fetching.length > 3 && this.user?.is_admin) {
                setTimeout(() => {
                    /* Disable laoder */
                    this.isLoading = false;

                    /* Check if elippsis */
                    this.textOverFlow('.quoteitem .qoutebody');

                   
                }, 0);
            } else if(fetching.length >= 3) {
                setTimeout(() => {
                    /* Disable laoder */
                    this.isLoading = false;

                    /* Check if elippsis */
                    this.textOverFlow('.quoteitem .qoutebody');

                   
                }, 0);
            }
        },
        /*Watch search string  */
        searchStr() {
            /* Search filter */
            this.searchFilter();
        },
        /* Watch user change */
        user : {
            handler() {
                
                /* Get user total snippet */
                CombinedSnipetsApis.userTotalSnippet({user : this.user, callback : total => this.userSnippetTotal = total})
            },
            deep : true
        },
        /* Watch route and make calls */
        $route: {
            handler() {
                /* Re-call if route change */
                this.resetList();
                /* Get favorite */
                if(this.$route.meta.page == 'favorites') {
                    CombinedSnipetsApis.getFavSnippets({user_id : this.user?.id, callback : (data) => {
                        this.snippetFavList = data;
                        this.isLoading  = false;
                    }})
                }
                
            },
            deep: true
        },
        /* Watch merge quote and story changes  */
        mergedQuoteStory : {
            handler : _.debounce(function () {
               /* start highlightng */
               if(!this.isSearching) this.startHighlight(this.searchStr);
                
            }, 100),
            deep : true
        },
        /* Watch changes on approval count then update badge  */
        totalApprovalCount(count) {
           this.appBadgeNotifCount(count)
        }
    },
    computed: {
        /**
         * Snippet count 
         *  
         */
        snippetCount() {

            /* Admin approval */
            if(this.showApproval) return this.totalApprovalCount;

            /* Normal user fav */
            if(!this.user?.is_admin && this.$route.meta.page == 'favorites') return this.snippetFavList.length;

            return this.userSnippetTotal;
        },
        /* Newly added qutoe id */
        newQuoteAddedID : {
            get() {
                return this.$store.state.newQuoteAddedId;
            },
            set(id) {
                this.$store.commit('updateNewQuoteAddedId', id);
            }
        },
       
        /* Total quotes user has */
        totalQuotesAndStories () {
            let total = 0;
            
            /* Normal user */
            const quote = this.totalQuotes?.data ?? 0 ;
            const story = this.totalStories?.data ?? 0;
            total = quote + story;

            /* Admin */
            const pubQuotes  = this.totalPubQuotes?.data ?? 0;
            const pubStories = this.totalPubStories?.data ?? 0;

            if(this.user?.is_admin) {
                total =  pubQuotes + pubStories
            }

            /* Favorite */
            if(this.$route.meta.page == 'favorites') {
                const favQuotes  = this.combinedFavoritedQuoteStories?.favoriteQuotes?.length ?? 0;
                const favStories = this.combinedFavoritedQuoteStories?.favoriteStories?.length ?? 0;

                 total = favQuotes + favStories;
            }
            return total;
        },
        /* Show approval list */
        showApproval : {
            get() {
                return this.$store.state.showApproval;
            },
            set(state) {
                this.$store.commit('updateShowApprovalState', state);
            }
        },
        /* Logged in user */
        user() {
            return this.$store.state.user
        },
        /* Merge quote and story*/
        mergedQuoteStory() {
 
            /* Default */
            let list = [];

            list = [...this.snippetsList];

            

            /* If admin merge published */
            if(this.user?.is_admin) { 
                // /* Quotes */
            
                list = [...list.filter(l => l.publish_status || this.iOwned(l.created_by))];
            } else if(this.$route.name == 'favorites' || this.$route.name == 'libraryFavorites') {
             
                      list       = [...this.snippetFavList ];

            } else {
                list = [...list.filter(l => this.iOwned(l.created_by))];
            }
            
            // /* Merge private  and owned quote and story*/
            // /* prevent from merging if favorites */
          

            /* Return empty if no filter by */
            if (this.filterBy.type.length == 0) return [];

            /* Search filter */
            if(this.searchStr) {
                if(!this.user?.is_admin) list =  [...this.searchList.filter(l => this.iOwned(l.created_by))];
                if(this.user?.is_admin) list =  [...this.searchList.filter(l => this.iOwned(l.created_by) || l.admin_lock)];
            }
            

            //  /* Filter by quotes*/
        
            /* Filter snippets by category */
            list = [...list.filter(l => this.filterBy.type.includes(l.s_category))];


              /* Modify and add property */
            list = [...list.map(l => {
                /* Assign author property */
                const [author] = l.authors ? l.authors : []

                l.author = l?.author ?? author ?? l.author_id ?? null;


               /* Expose source date created */
                l.source_date_created = l?.s_date ?? '01/01/1770';

                /* Expost source */
                l.source = l.source ?? l.source_id ?? {};
                
                if(l.source) {
                    /* Expose source container like story has different property name */
                    l.source.containers = l.source?.scontainers ?? l.source?.containers ?? [];
                }
                
             

                 /* Check list if user added to favorite */
                l.isFav = false

                if(l.favorited_by?.map(f => f.id).includes(this.user?.id)) {
                    l.isFav = true
                }

                /*  Check if has file attachment  */
                if(l.files && l.files.length > 0) {
                    l.attachment = _.head(l.files.sort((a, b) => b.id - a.id));
                } else {
                    l.attachment = null; // default
                }


                /* Expose tags */
                l.tags = l?.tags ?? l?.quote_tags ?? l?.story_tags ?? [];

            
                return l
            })]

            /* Sort by latest */
            list = [...this.quoteSorting({data : list, sorting : this.filterBy.sortBy, })]
           

            /* Filter visible quote and story they should be equal or above of the user level */
            list = [...list.filter(l =>this.isShow(l, 'owner') || l.user_level <= this.user.user_level )];

            /* Remove duplicates */
            list = [...list.filter((l, i , a) => a.findIndex(fl => fl.id == l.id && fl.quote == l.quote) == i)]


            /* Default */
            return list
        }
    },
    methods: {
        /**
         * App Notif Badge Count
         * @param {Number} count
         */
        appBadgeNotifCount(count = 0) {
            // Check for support first.
            if (navigator.setAppBadge) {
                if(count > 0) {
                    // Display the number of unread messages.
                    navigator.setAppBadge(count);

                } else {
                    /* Clear if no count */
                    navigator.clearAppBadge();

                }
            }
        },  
       /**
        * Enable animation popout for newly created qutoe / story
        * @param {Object} item
        */
        showCardEffect(item) {
             if(this.newQuoteAddedID?.id == item.id && this.newQuoteAddedID?.quote == item?.quote) return true;
             if(this.newQuoteAddedID?.id == item.id && this.newQuoteAddedID?.story == item?.story) return true;

             return false;
        },
        /**
         * Get all quote and stories in 1 apis no pagignation
         * @param {Function} callback 
         */
        async getAllStoriesAndQuotes({params = {sorting : 'desc', page : 1}, callback = () => {}} = {}) {
            const url = `/api/story/allstoryquotes?${new URLSearchParams(params)}`; 


            /* Fetch callback  */
            const fetchCallback = ({res, isRefetch = false} = {}) => {
                console.log('fetch callback')

                if(res) {
                    /* Update localdb */

                    if(params.page > 1) {
                        let fetchRes                           = {...res};
                            fetchRes.quotes.data               = [...this.combinedQuotesStories.quotes.data, ...fetchRes.quotes.data];
                            fetchRes.stories.data              = [...this.combinedQuotesStories.stories.data, ...fetchRes.stories.data];
                            fetchRes.visiblehiddenquotes.data  = [...this.combinedQuotesStories.visiblehiddenquotes.data, ...fetchRes.visiblehiddenquotes.data];
                            fetchRes.visiblehiddenstories.data = [...this.combinedQuotesStories.visiblehiddenstories.data, ...fetchRes.visiblehiddenstories.data];
                            this.combinedQuotesStories         = {...fetchRes};
                    }
                    
                    if(params.page == 1) this.combinedQuotesStories = res;


                    if(isRefetch) {
                         /* check if there's new quote added */
                        if(this.newQuoteAddedID) {
                            setTimeout(() => {
                                this.newQuoteAddedID = null;
                            }, 1000);
                        }
                    }

                    callback();

                   
                }
            }


            const res = await this.cacheReturn({
                url, 
                method: "GET",
                callback : (res) => {fetchCallback({res, isRefetch : true})}
            })

            console.log('getAllStoriesAndQuotes', res)

            fetchCallback({res});
        },
        /**
         * Get total number of quotes and stories (Published);
         * For admin only this will not run for normal users
         */
        async getTotalQuotesStoriesPub() {


            const quotesUrl = `/api/quote/total`;
            const storyUrl  = `/api/story/total`;
            // const quotesRes = await axios.get(quotesUrl).catch(err => console.log(err));
            // const storyRes = await axios.get(storyUrl).catch(err => console.log(err));

             const quotesRes = await this.cacheReturn({
                url : quotesUrl, 
                method: "GET",
                callback : res => {
                    if(res) this.totalPubQuotes = res;
                }
            })

            

            this.totalPubQuotes  = quotesRes;


             const storyRes = await this.cacheReturn({
                url : storyUrl, 
                method: "GET",
                callback : res => {
                    if(res) this.totalPubStories  = res;
                }
            })

            this.totalPubStories = storyRes;
            
        },
        /**
         * Get total number of quotes and stories (Personal)
         */
        async getTotalQuotesStories() {
            const quotesUrl = `/api/story/personaltotal`;
            const storyUrl  = `/api/quote/personaltotal`;
            // const quotesRes = await axios.get(quotesUrl).catch(err => console.log(err));
            // const storyRes  = await axios.get(storyUrl).catch(err => console.log(err));

            const quotesRes = await this.cacheReturn({
                url : quotesUrl, 
                method: "GET",
                callback : res => {
                    if(res) this.totalQuotes = res;
                }
            })

            const storyRes = await this.cacheReturn({
                url : storyUrl, 
                method: "GET",
                callback : res => {
                    if(res) this.totalStories = res;
                }
            })

            this.totalQuotes  = quotesRes;
            this.totalStories = storyRes;
            
        },
        /**
         * Toggle search
         * show / hide toggle search field
         * reset search highlight
         */
        toggleSearch(){
            this.showSearch = !this.showSearch;
            if(!this.showSearch) this.searchStr  = '';

            /* Check if elippsis */
            this.textOverFlow('.quoteitem .qoutebody');
            
        },
        /**
         * Toggle approval list
         * this should diactivate search field if it's active and reset word highlight
         */
        toggleApproval() {
            if(this.showSearch) {
                this.showSearch = false;
                this.searchStr  = '';
            } 

            this.showApproval = !this.showApproval;
            

        },
        /* Get approval list count */
        getApprovalListCount() {
             this.getTotalApprovalQuote({callback : (data) => {
                this.approvalQuotes = data;
             }});
             this.getTotalApprovalStories({callback : (data) => {
                this.approvalStories = data;
             }});
        },
         /* Fetching more quote and stories */
         fetchMoreQuoteStory : _.debounce(function() {
            if(this.fetching) return;
            if(this.searchStr) return; //prevent fetching more if searching;
            this.fetching = true;
            this.currentPageCombined++;

            /* Prevent from fetchting if no more to fetch */
            if(!this.combinedQuotesStories?.stories?.links?.next && !this.combinedQuotesStories?.quotes?.links?.next && !this.combinedQuotesStories?.visiblehiddenstories?.links?.next  && !this.combinedQuotesStories?.visiblehiddenquotes?.links?.next) return;

            this.getStoriesAndQuote({isNext: true, callback : () => {
                this.fetching = false;
            }})

         }, 300),
        /* Show action buttons */
        isShow(item, type) {
          
            if(this.$route.meta.page == 'favorites') return false; // don't show if favorite page
          
            if(!this.iOwned(item.created_by)) return false;
            

            /* if type is owner and user is the owner of story and quote then show it */
            if(type == 'owner') return true

            /* Desktop */
            if(window.innerWidth > 1200 && type == 'action') return true


            /* Mobile */
            if(`${item.id}${item.story ? 'story' : 'quote'}` == this.activeaction) return true


            return false
        },
       
        /* Delete story / quote */
        deleteItem(item) {

            /* Remove from the list and hide modal */
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                /* Remove quote */
                if(modalId == 'deleteQuote' && bvEvent.type == 'hide') {
                    this.quoteList = [...this.quoteList.filter(q => q.id != item.id)]

                    return
                }
                /* Remove story */
                if(modalId == 'deleteStory' && bvEvent.type == 'hide') {
                    this.storyList = [...this.storyList.filter(s => s.id != item.id)]
                }
            })


            /* Delete quote */
            if(item.quote) {

                /* Assign item id to confirmation modal */
                const confirmation = this.$refs.deleteQuote

                if(!confirmation) return
                confirmation.quoteID = item.id;

                /* add neetwork first for quote list */
                this.addNetworkFirst('quoteList')
                
                
                this.$bvModal.show('deleteQuote')
                
                return
            }

            /* Delete Story */
            if(item.story) {
                const confirmatiion = this.$refs.deleteStory
                /* Assign story id to delete confirmation */
                if(!confirmatiion) return
                confirmatiion.storyID = item.id;

                this.addNetworkFirst('storyList');

                this.$bvModal.show('deleteStory')
            }
        },
        /* Resets list */
        resetList() {
            this.isLoading             = true;
            this.currQuotePage         = null;
            this.quoteList             = [];
            this.privateQList          = [];
            this.privateSList          = [];
            this.currStoryPage         = null;
            this.storyList             = [];
            this.combinedQuotesStories = {};
            
            this.reRender              = Math.random();
            /* Reset Scroll */
            this.resetScroll(this.$refs.homeBody);
        },
        /* Search filter */
         searchFilter : _.debounce(async function() {
            this.fetchingQuoteStories = [];
            this.processingHighlight = true;

            /* If empty search string return original list */
            if(this.searchStr == '') {
                /* Get stories and quotes */
                // await this.getStoriesAndQuote();
                this.processingHighlight = false;
                this.isSearching         = false;
                
                return;
            }


            // return list.filter(l => {
            //     const searchBase = `${l.story ? l.story : ''} ${l.quote ? l.quote : ''} ${l.author ? `${l.author.first_name} ${l.author.last_name}` : ''} ${l.source ? l.source.source_name : ''}`.toLowerCase();
            //     return searchBase.match(this.searchStr.toLowerCase())
            // })


            const searchString = this.searchStr.toLowerCase()
            // const searchString = StringParse.removeSpecialCharacter(this.searchStr.toLowerCase())

            // const params = {
            //     searchtext : searchString
            // }

            // await this.searchQuote({params, callback : (data) => {
            //     // list = [...list, ...data.data.filter((d, i, a) => a.findIndex(df => df.id == d.id) == i)];
            //     list = [...list, ...data.quotes, ...data.stories];
            // }})


            await CombinedSnipetsApis.searchSnippet({params : {searchtext : searchString}, callback : (data) => {
                console.log('search snippet', data);

                this.searchList           = data
                this.fetchingQuoteStories = ['quote', 'published', 'story', 'private']
                this.isSearching          = false;
            }})
          

            // /* Check if admin or normal user */
            // if(!this.user?.is_admin) {
            //     /* if not admin only return quotes that is belong to the user or favorited */
            //     // if(this.$route.meta.page == 'favorites') list = [...list.filter(l => l.favorited_by.find(lf => lf.id == this.user?.id))]
            //     const favQuotes                                  = this.combinedFavoritedQuoteStories?.favoriteQuotes ?? [];
            //     const favStories                                 = this.combinedFavoritedQuoteStories?.favoriteStories ?? [];
            //     if   (this.$route.meta.page == 'favorites') list = [...favQuotes, ...favStories]
            //     else  list                                       = [...list.filter(l => this.iOwned(l.created_by))];
            // } 

            // if(this.user?.is_admin) {
            //      /* Check list for approval don't include if not the owned */
            //     list = [...list.filter(l => this.iOwned(l.created_by) || l.publish_status)];
            // }

           


        
        }, 500),
        /* Toggle fav  */
        remove(item) {

            let combinedFavorites = {...this.combinedFavoritedQuoteStories};
            let newFavQuotes      = [...Array.from(combinedFavorites.favoriteQuotes)];
            let newFavStories     = [...Array.from(combinedFavorites.favoriteStories)];

            
            /* Story favorite */
            if(item.story) {
                this.addNetworkFirst('storyList');
                
                
                /* Add / remove story to favorite */
                if(item.favorited_by && item.isFav) {
                    this.removeFavoriteStory({
                        params : {
                            story_id : item.id,
                        },
                        callback : () => {
                            /* Emptied out  favorited_by to remove marking*/
                            newFavStories = [...newFavStories.map(s => {
                                if(s.id == item.id) {
                                    s.favorited_by = [...s.favorited_by.filter(sfy => sfy.id != this.user?.id)]
                                    // s.isFav = false
                                }

                                return s
                            })]


                            /* Remove item if in favorite page */
                            if(this.$route.meta.page == 'favorites') {
                                newFavStories = [...newFavStories.filter(f => f.id != item.id)]
                            }

                            combinedFavorites.favoriteStories = newFavStories;

                            this.combinedFavoritedQuoteStories = {...combinedFavorites};

                            /* Update cache */
                            this.getAllFavQuoteStories();

                          
                            // this.privateSList = [...this.privateSList.map(s => {
                            //     if(s.id == item.id) {
                            //         s.favorited_by = [...s.favorited_by.filter(sfy => sfy.id != this.user?.id)]
                            //         // s.isFav = false
                            //     }

                            //     return s
                            // })]


                            /* Rerender */
                            // this.reRender = Math.random();

                            /* Update list  */
                            // if(this.$route.name != 'favorites') return
                            // this.storyList = [...this.storyList.filter(fs => fs.isFav)]

                        }
                    })
                } else {
                    this.addFavoriteStory({
                        params : {
                            story_id : item.id,
                        },
                        callback : () => {
                            /* Add data to favorited_by to set the marker*/
                            combinedFavorites.favoriteStories = [...newFavStories.map(s => {
                                if(s.id == item.id) {
                                    s.favorited_by = [...s.favorited_by, this.user]
                                }

                                return s
                            })]

                            this.combinedFavoritedQuoteStories = {...combinedFavorites};

                               /* Update cache */
                            this.getAllFavQuoteStories();

                            
                            // this.privateSList = [...this.privateSList.map(s => {
                            //     if(s.id == item.id) {
                            //         s.favorited_by = [...s.favorited_by, this.user]
                            //     }

                            //     return s
                            // })];

                            //   /* Rerender */
                            //   this.reRender = Math.random()
                        }
                    })
                }

                return;
            }

            /* Quote favorite */
            this.addNetworkFirst('quoteList');

            /* add and remove to favorite */
            if (item.favorited_by && item.isFav) {

                this.removeFavorite({
                    params: {
                        quote_id: item.id
                    },
                    callback : () => {
                        /* Set favorited_by empty */

                        newFavQuotes = [...newFavQuotes.map(q => {

                            if(q.id == item.id) {
                                q.favorited_by = [...q.favorited_by.filter(fqy => fqy.id != this.user?.id)]
                            }
                                

                            return q
                        })]
                    
                        // this.privateQList = [...this.privateQList.map(q => {

                        //     if(q.id == item.id) {
                        //         q.favorited_by = [...q.favorited_by.filter(fqy => fqy.id != this.user?.id)]
                        //     }
                                

                        // return q
                        // })]

                        // /* Rerender */
                        // this.reRender = Math.random()

                        /* Remove item if in favorite page */
                        if(this.$route.meta.page == 'favorites') {
                            newFavQuotes = [...newFavQuotes.filter(f => f.id != item.id)]
                        }

                        console.log(newFavQuotes, 'removing quote favorite', combinedFavorites)

                        combinedFavorites.favoriteQuotes = newFavQuotes;

                        /* Update list  */
                        // if(this.$route.name != 'favorites') return
                        // this.quoteList = [...this.quoteList.filter(fq => fq.isFav)]

                        this.combinedFavoritedQuoteStories = {...combinedFavorites};

                           /* Update cache */
                            this.getAllFavQuoteStories();

                    }
                })
            } else {
              this.addFavorite({
                    params: {
                        quote_id: item.id
                    },
                    callback : () => {

                        /* update quote favorited_byu  */
                        combinedFavorites.favoriteQuotes = [...combinedFavorites.favoriteQuotes.map(q => {
                            if(q.id == item.id) {
                                q.favorited_by = [...q.favorited_by, this.user]
                            }
                            
                            return q
                        })]
                        
                        // this.privateQList = [...this.privateQList.map(q => {
                        //     if(q.id == item.id) {
                        //         q.favorited_by = [...q.favorited_by, this.user]
                        //     }
                            
                        //     return q
                        // })]

                        //  /* Rerender */
                        //  this.reRender = Math.random()

                        this.combinedFavoritedQuoteStories = {...combinedFavorites}

                           /* Update cache */
                            this.getAllFavQuoteStories();
                    }
                })
            }


         
        },
        /**
         * Swipe listener
         */
        showactions(e, id, options) {
            console.log(e, id)

            if (e.directions.left) {
                const showid = `${id}${options.type}`
                this.activeaction = this.activeaction == showid ? null : showid;
                
            }

        },
        /* Get stories and quote */
        getStoriesAndQuote : _.debounce(async function({isNext = false, callback = ()=> {}} = {}) {
            this.activeaction = null; // hide action 
                
                console.log(isNext, callback)

                /* Get local quotes and stories */
                // this.localQuotesStories = await StoryQuoteMixins.getQuoteAndStories(); 

                let params = {
                    sorting: this.filterBy.sortBy,
                    page   : 1
                }

                if(this.currentPageCombined > 1 && isNext) {
                    params.page = this.currentPageCombined;
                }

                // /* Get Songs */
                // this.getCombinedSongs({params, callback : data => {

                //      if(isNext) {
                //         const newData                = {...data};
                //               newData.visible.data   = [...this.combinedSongs?.visible?.data ?? [], ...newData?.visible.data ?? []];
                //               newData.hidden.data    = [...this.combinedSongs?.hidden?.data ?? [], ...newData?.hidden.data ?? []];
                //               newData.published.data = [...this.combinedSongs?.published?.data ?? [], ...newData?.published.data ?? []];
                //               this.combinedSongs     = newData;
                //         return;
                //     }

                //     this.combinedSongs = data;
                // }});
                
                // /* Get poem */
                // this.getCombinedPoems({params, callback : data => {

                //     if(isNext) {
                //         const newData                = {...data};
                //               newData.visible.data   = [...this.combinedPoems?.visible?.data ?? [], ...newData?.visible.data ?? []];
                //               newData.hidden.data    = [...this.combinedPoems?.hidden?.data ?? [], ...newData?.hidden.data ?? []];
                //               newData.published.data = [...this.combinedPoems?.published?.data ?? [], ...newData?.published.data ?? []];

                //         this.combinedPoems = newData;

                //         return;
                //     }
                //     this.combinedPoems = data;
                // }})

                /* Get all quotes and stories */
                // this.getAllStoriesAndQuotes({params, callback : () => {
                //     if(!this.user?.is_admin) {
                //         this.fetchingQuoteStories = [...this.fetchingQuoteStories, 'quotes', 'stories', 'published'];

                //     } else {
                //         this.fetchingQuoteStories = [...this.fetchingQuoteStories, 'quotes', 'stories', 'favorites', 'published'];
                //     }

                //     callback();
                    
                // }});

                // if(!this.user?.is_admin && this.$route.meta.page == 'favorites') {
                //     this.getAllFavQuoteStories({params, callback : (data) => {
                //         this.combinedFavoritedQuoteStories = data;
                //         this.fetchingQuoteStories = [...this.fetchingQuoteStories, 'favorites'];

                //          callback();
                //     }});
                // }
                

        //     /* Get favorites */

        //     if (this.$route.meta.page == 'favorites') {
                
        //         /* Check if next  */
        //         const quoteParams = {
        //             params : {
        //                 page   : isNext && this.currQuotePage && this.currQuotePage.links && this.currQuotePage.links.next ? (this.currQuotePage.meta.current_page + 1): 1,
        //                 sorting: this.filterBy.sortBy,
        //             },
        //             callback: (data) => {
        //                 this.currQuotePage = data;
        //                 this.favoritedQuotes     = isNext ? [...this.favoritedQuotes, ...data.data] : data.data;

        //                 this.fetchingQuoteStories = [...this.fetchingQuoteStories, 'fav quote', 'fav quote'];
        //             }
        //         }

        //         const storyParams = {
        //             params : {
        //                 page   : isNext && this.currStoryPage && this.currStoryPage.links && this.currStoryPage.links.next ? (this.currStoryPage.meta.current_page + 1): 1,
        //                 sorting: this.filterBy.sortBy,
        //             },
        //             callback: (data) => {
        //                 this.currStoryPage = data
        //                 this.favoritedStories     = isNext ? [...this.favoritedStories, ...data.data] : data.data;
        //                 this.fetchingQuoteStories = [...this.fetchingQuoteStories, 'story', 'fav story'];
        //             }
        //         }

        //         if(isNext) {
                    
        //             if(this.currQuotePage?.links?.next) {
        //                 /* Get favorite quotes */
        //                 await this.getFavorites(quoteParams);
                        
        //             }

        //             if(this.currStoryPage?.links?.next) {
        //                 /* Get favorite stories */
        //                 await this.getFavoriteStories(storyParams);
        //             }

        //         } else {
        //              /* Get favorite quotes */
        //              await this.getFavorites(quoteParams);
        //              /* Get favorite stories */
        //              await this.getFavoriteStories(storyParams);
        //         }
             

        //         callback();

        //         return;
        //     }

        //     /* Get combined quotes */
        //     this.getQuotesCombined({params : {sorting : this.filterBy.sortBy, page : this.currentPageCombined}, callback : (data) => {
        //         if(this.currentPageCombined > 1 && !data.hidden.links.next && !data.published.links.next && !data.visible.links.next) return; // prevent from updating data whe they are all reach the last page

        //         let newData = data;

        //         newData.visible.data   = [...this.combinedQuotes?.visible?.data ?? [], ...newData.visible.data]
        //         newData.hidden.data    = [...this.combinedQuotes?.hidden?.data ?? [], ...newData.hidden.data]
        //         newData.published.data = [...this.combinedQuotes?.published?.data ?? [], ...newData.published.data]

        //         this.combinedQuotes = newData;

        //         this.fetchingQuoteStories =[...this.fetchingQuoteStories, 'quote', 'published']
        //     }});
           
        //    /* Get combined Stories */
        //     this.getStoriesCombined({params : {sorting : this.filterBy.sortBy, page : this.currentPageCombined}, callback : (data) => {
               
        //         if(this.currentPageCombined > 1 && !data.hidden.links.next && !data.published.links.next && !data.visible.links.next) return; // prevent from updating data whe they are all reach the last page

        //         let newData = data;

        //         newData.visible.data   = [...this.combinedStories?.visible?.data ?? [], ...newData.visible.data]
        //         newData.hidden.data    = [...this.combinedStories?.hidden?.data ?? [], ...newData.hidden.data]
        //         newData.published.data = [...this.combinedStories?.published?.data ?? [], ...newData.published.data]

        //         this.combinedStories = newData;

        //         this.fetchingQuoteStories =[...this.fetchingQuoteStories, 'stories', 'published']
        //     }});
            
          
            this.fetching = false;
        }, 0)
    },
    beforeMount() {
        /* Update app badge count on load */
        this.appBadgeNotifCount(this.totalApprovalCount || 0)

        /* local db synching */
        
        const startTime  = new Date();
        let loadedTime = false;
        CombinedSnipetsApis.getList({abortCallback : (controller) => {
             this.$store.commit('updateAbortRequest', [...this.$store.state.abortRequest, {
                id     : Helper.makeString(5),
                page   : this.$route.name,
                request: controller
            }])
        }, callback : (data) => {
            this.snippetsList = [...this.snippetsList, ...data].filter((s, i, a) => a.findIndex(sf => sf.id == s.id) == i); //remove duplicate
            console.log('snippet list', this.snippetsList)
            this.isLoading = false;
            this.fetching  = false;
            console.log('Snippet without tags')
            console.table(this.snippetsList.filter(s => s.tags.length == 0))

            if(!loadedTime) {
                loadedTime = true;
                let endTime = new Date();
                let timeElapsed = endTime - startTime;
    
                console.log('snippet load time', timeElapsed);
            }
            /* Get Snippet count */
            CombinedSnipetsApis.userTotalSnippet({user : this.user, callback : total => this.userSnippetTotal = total})
            
        }});

        if(this.$route.meta.page == 'favorites') {
            CombinedSnipetsApis.getFavSnippets({user_id : this.user?.id, callback : (data) => {
                this.snippetFavList = data;
                this.isLoading  = false;
            }})
        }

        /* Get user total snippet */
        CombinedSnipetsApis.userTotalSnippet({user : this.user, callback : total => this.userSnippetTotal = total})
        
        // /* Get stories and quotes */
        // this.getStoriesAndQuote({callback : () => {

        //     /* Get approval list counts */
        //     this.getApprovalListCount();
          
        //     /* Get total number of quotes and stories */
        //     this.getTotalQuotesStories();
            
        //     /* Get total number of published quotes and stories */
        //     this.getTotalQuotesStoriesPub();
        // }});
       

        /* Set toggle color */
        if(this.settings?.viewMode == 'dark') {
            this.setTopBarColor();
        } else {
            this.setTopBarColor({color : '#fff'});
        }
    },
    mounted() {
        /* Reset Scroll */
        this.resetScroll(this.$refs.homeBody);

        // if(this.combinedQuotesStories) {
        //     this.isLoading = false;

        //     this.textOverFlow('.quoteitem .qoutebody');
        // }
    },  
    beforeRouteLeave(to, from, next) {
        this.showSearch = false;

        next();
    }
};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";
@import "@/scss/_mixins.scss";

.homeview {
    // .header {
    //     svg {
    //         width: 18px;
    //         height: 18px;
    //     }

    //     .gear {
    //         svg {
    //             width: 20px;
    //             height: 20px;
    //         }
    //     }
    // }

    .newQuoteOpt {
        box-shadow: 0 4px 8px $lightgray10;

        .newQuoteOptItem {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .filterPopWrap {
        position        : absolute;
        top             : 70px;
        background-color: #fff;
        width           : 100%;
        padding         : 30px;
        box-shadow      : 0 4px 8px $lightgray10;

        legend {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 15px;
            padding-bottom: 0;
        }

        .custom-control-label {
            font-size: 15px;
        }
    }

    .homebody {
        padding-bottom  : 120px;
        background-color: $lightgray;
        overflow        : hidden auto;
        // height          : calc(100vh - 160px);

        .quotelist {
            margin-top: 15px;
        }

        h1 {
            font-size: 20px;
            margin-bottom: 20px;
        }

      
    }
}
</style>
